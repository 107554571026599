const Sentry = require('@sentry/node');
// const Cookie = require('js-cookie');

const appName = process.env.HEROKU_APP_NAME;
const releaseVersion = process.env.HEROKU_RELEASE_VERSION;
const isDev = process.env.NODE_ENV !== 'production';

module.exports = () => {
  const sentryOptions = {
    dsn: !isDev && 'https://1dd1c70abe344e6fb871147965e67b1b@sentry.io/1424223',
    release: `${releaseVersion}`,
    environment: `${appName}`,
    maxBreadcrumbs: 50,
    attachStacktrace: true
  };

  if (!isDev) {
    Sentry.init(sentryOptions);
  }

  return {
    Sentry,
    captureException: (err, { req, res, errorInfo, query, pathname }) => {
      Sentry.configureScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (res && res.statusCode) {
          scope.setExtra('statusCode', res.statusCode);
        }

        if (process.browser) {
          scope.setTag('ssr', false);
          scope.setExtra('query', query);
          scope.setExtra('pathname', pathname);

          // On client-side we use js-cookie package to fetch it
          // const sessionId = Cookie.get('ASP.NET_SessionId');
          // if (sessionId) {
          //   scope.setUser({ id: sessionId });
          // }
        } else {
          scope.setTag('ssr', true);
          scope.setExtra('url', req.url);
          scope.setExtra('method', req.method);
          scope.setExtra('headers', req.headers);
          scope.setExtra('params', req.params);
          scope.setExtra('query', req.query);

          // On server-side we take session cookie directly from request
          // if (req.cookies['ASP.NET_SessionId']) {
          //   scope.setUser({ id: req.cookies['ASP.NET_SessionId'] });
          // }
        }

        if (errorInfo) {
          Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]));
        }
      });

      return Sentry.captureException(err);
    }
  };
};
