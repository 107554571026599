import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Script from 'next/script';

const ZendeskChat = () => {
  const { language } = useSelector((s) => s.viewState) || {};
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) return; // ? Safety check: avoid calling ZendeskAPI before it's loaded

    window.zE('webWidget', 'setLocale', language || 'en');
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        chat: {
          connectOnPageLoad: false,
          concierge: {
            title: {
              '*': 'Please ask us!',
              sv: 'Fråga oss gärna!',
              de: 'Bitte fragen Sie uns!',
            },
          },
        },
      },
    });
  }, [loaded, language]);

  // ? Memoize the Zendesk component so it's only created once, avoids calling ZendeskAPI before script is loaded
  return useMemo(
    () => (
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=d350bbba-dbe2-4bf8-99f9-f4637ccbc9ef"
        strategy="afterInteractive"
        onLoad={() => setLoaded(true)}
      />
    ),
    []
  );
};

export default ZendeskChat;
