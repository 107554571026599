export const color = {
  black: '#000000',
  white: '#ffffff',
  grey: '#efefef',
  superGrey: '#8d8d8d',
  lightGrey: '#f9f9f9',
  brightGrey: '#c6c6c6',
  borderGrey: '#e5e5e5',
  hoverGrey: '#8d8d8d',
  darkSepia: '#cabfb1',
  activeGreen: '#4c6964',
  productImageBackground: '#f5f4f0',
  red: '#5f2d3a',
  primary: '#f4f4ef',
  disabled: '#9d9d9d',
  success: '#189148'
};

export const sizes = {
  xSmall: 400,
  small: 512,
  medium: 768,
  large: 1024,
  xLarge: 1280,
  xXLarge: 1640,

  pageMaxWidth: 1720,
  sliderBreakPoint: 1155,
  footerBreakPoint: 840,
  headerBreakpoint: 1220,
  HeroImageBlockBreakpoint: 590
};

export const margins = {
  xSmall: 25,
  small: 30,
  medium: 40,
  large: 80,
  xLarge: 120
};

export const thresholds = {
  seeMoreArticles: 8,
  seeMoreSustainabilityArticles: 11,
  disabledOpacity: 0.5
};

export const regExPatterns = {
  email: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/, //eslint-disable-line
  phone: /^\+[0-9]{11}$/ //eslint-disable-line
};

export const isIE =
  typeof window !== 'undefined' &&
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style;
