import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { fetchBootstrap } from '../houdini';

import { GET_BOOTSTRAP_REQUEST } from '../../store/actions/actionTypes';
import { bootstrapResponse } from '../../store/actions/actions';

es6promise.polyfill();

function* handleBootstrapRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(fetchBootstrap, action.payload),
      cancel: take(GET_BOOTSTRAP_REQUEST)
    });

    if (cancel) {
      yield put(bootstrapResponse({ status: 'CANCELED' })); // TODO: Test cancelation of requests when we have more data.
    }
    yield put(bootstrapResponse({ payload }));
  } catch (error) {
    yield put(bootstrapResponse({ error }));
  }
}

export const bootstrapSaga = function* productSaga() {
  yield all([takeEvery(GET_BOOTSTRAP_REQUEST, handleBootstrapRequested)]);
};
