import Script from 'next/script';

type Props = {
  marketLanguage: string;
};

export const AppScripts: React.FC<Props> = (props) => {
  const { marketLanguage } = props;
  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
            var dataLayer = dataLayer || [];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&NEXT_PUBLIC_GTM_AUTH=${process.env.NEXT_PUBLIC_GTM_AUTH}&NEXT_PUBLIC_GTM_PREVIEW=${process.env.NEXT_PUBLIC_GTM_PREVIEW}';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            `}
      </Script>

      <Script
        src={`https://js.testfreaks.com/onpage/houdinisportswear.com-${marketLanguage}${
          process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' ? '-staging' : ''
        }/head.js`}
      />

      <Script
        src="https://scripts.webapp.prod.soultech.cloud/custom_houdini.js"
        strategy="lazyOnload"
      />
    </>
  );
};
