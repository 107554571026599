import {
  get,
  getOr,
  reduce,
  map,
  find,
  split,
  takeRight,
  maxBy,
} from 'lodash/fp';
import { splitMarket } from './splitMarket';

const takeLastValue = (divider, string) => {
  return get(0, takeRight(1, split(divider, string)));
};

const isGTMInitiated = () => {
  return !!get('google_tag_manager', window);
};

const buildProductObjectFromOrderRow = (orderRow, quantity = 1) => {
  const { product, rowPrice } = orderRow;

  return {
    id: takeLastValue('-', get('id', product)),
    name: get('title', product),
    price:
      get('reducedValueWithoutVat', rowPrice) ||
      get('listValueWithoutVat', rowPrice),
    priceWithVat: get('reducedValue', rowPrice) || get('listValue', rowPrice),
    category: takeLastValue('/', get(['category', 'id'], product)),
    quantity,
    dimension1: getOr(undefined, ['size', 'articleId'], product),
    dimension2: getOr(undefined, ['color', 'title'], product),
    dimension3: getOr(undefined, ['size', 'shortTitle'], product),
    dimension4: get(['tracking', 'productType'], product),
    dimension5: get(['tracking', 'productSegment'], product),
    dimension6: get(['tracking', 'gender'], product),
    dimension7: get(['tracking', 'ageGroup'], product),
  };
};

const sendEvent = (eventObject) => {
  // debug console.log that logs every GTM event
  // console.log('GTM EVENT:', eventObject);

  if (typeof window === 'undefined' || !window.dataLayer) return false;

  // eslint-disable-next-line no-undef
  setTimeout(() => {
    window.dataLayer.push(eventObject);
  }, 0);

  return true;
};

export const changeMarketEvent = ({ eventCallback, from, to }) => {
  const [fromLanguage, fromCountry] = splitMarket(from);
  const [toLanguage, toCountry] = splitMarket(to);

  if (!isGTMInitiated()) eventCallback();

  return sendEvent({
    event: 'changeMarket',
    fromLanguage,
    fromCountry,
    toLanguage,
    toCountry,
    eventCallback,
  });
};

export const newsletterManualOpenEvent = () => {
  return sendEvent({
    event: 'newsletterManualOpen',
  });
};

export const newsletterAutoOpenEvent = () => {
  return sendEvent({
    event: 'newsletterAutoOpen',
  });
};

export const newsletterSignupEvent = () => {
  return sendEvent({
    event: 'newsletterSignupSubmitted',
  });
};

export const testFreaksBadgeClickEvent = () => {
  return sendEvent({
    event: 'testFreaksBadgeClicked',
  });
};

export const testFreaksReviewsOpenEvent = () => {
  return sendEvent({
    event: 'testFreaksReviewsOpened',
  });
};

export const socialLinkClickEvent = (socialLinkName) => {
  return sendEvent({
    event: 'socialLinkClick',
    socialLinkName,
  });
};

export const pageViewEvent = () => {
  return sendEvent({
    event: 'pageView',
  });
};

export const productImpressionEvent = (products) => {
  const productImpressionsData = reduce(
    (memo, product) => {
      return [
        ...memo,
        {
          id: get(0, takeRight(1, split('-', get('id', product)))),
          name: get('title', product),
          category: get(
            0,
            takeRight(1, split('/', get(['category', 'id'], product)))
          ),
          dimension2: get(['variants', 0, 'color', 'title'], product),
        },
      ];
    },
    [],
    products
  );

  return sendEvent({
    event: 'productImpression',
    ecommerce: {
      impressions: productImpressionsData,
    },
  });
};

export const productDetailEvent = (
  currentProduct,
  currentVariant,
  currentSKU,
  currencyCode
) => {
  // if there is no currentSKU, select the SKU with the highest price within the currentVariant
  const displaySKU =
    currentSKU ||
    maxBy(
      (size) => parseInt(get(['price', 'sortingPrice'], size), 10),
      get('sizes', currentVariant)
    );

  return sendEvent({
    event: 'productDetail',
    ecommerce: {
      currencyCode,
      detail: {
        products: [
          {
            id: takeLastValue('-', get('id', currentProduct)),
            name: get('title', currentProduct),
            price:
              get(['price', 'reducedValueWithoutVat'], displaySKU) ||
              getOr(undefined, ['price', 'listValueWithoutVat'], displaySKU),
            priceWithVat:
              get(['price', 'reducedValue'], displaySKU) ||
              getOr(undefined, ['price', 'listValue'], displaySKU),
            category: takeLastValue(
              '/',
              get(['category', 'id'], currentProduct)
            ),
            dimension1:
              get('articleId', currentSKU) ||
              getOr(undefined, 'id', currentVariant),
            dimension2: getOr(undefined, ['color', 'title'], currentVariant),
            dimension3: getOr(undefined, 'shortTitle', currentSKU),
          },
        ],
      },
    },
  });
};

export const addToCartEvent = (data, currencyCode) => {
  const { id, cart, orderRow, quantity } = data;
  const verifiedOrderRow =
    orderRow ||
    find(
      (row) => get(['product', 'size', 'articleId'], row) === id,
      get('orderRows', cart)
    );

  return sendEvent({
    event: 'addToCart',
    ecommerce: {
      currencyCode,
      add: {
        products: [buildProductObjectFromOrderRow(verifiedOrderRow, quantity)],
      },
    },
  });
};

export const removeFromCartEvent = (data, currencyCode) => {
  const { orderRow, quantity } = data;
  return sendEvent({
    event: 'removeFromCart',
    ecommerce: {
      currencyCode,
      remove: {
        products: [buildProductObjectFromOrderRow(orderRow, quantity)],
      },
    },
  });
};

export const productVideoPlay = (src) => {
  return sendEvent({
    event: 'productVideoPlay',
    videoSrc: src,
  });
};

export const checkoutEvent = (data) => {
  const { cart, step, currencyCode } = data;

  if (!cart) {
    return sendEvent({
      event: 'checkout',
      ecommerce: {
        currencyCode,
        checkout: {
          actionField: {
            step,
          },
        },
      },
    });
  }

  return sendEvent({
    event: 'checkout',
    ecommerce: {
      currencyCode,
      checkout: {
        actionField: {
          step,
        },
        products: map(
          (row) =>
            buildProductObjectFromOrderRow(
              row,
              getOr(undefined, 'quantity', row)
            ),
          getOr([], 'orderRows', cart)
        ),
      },
    },
  });
};

export const purchaseEvent = (cart, currencyCode) => {
  const {
    email,
    phone,
    firstName,
    lastName,
    streetAddress,
    city,
    stateOrProvince,
    zip,
    country,
  } = cart?.billingCustomerInfo || cart?.deliveryCustomerInfo;

  return sendEvent({
    event: 'purchase',
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id: getOr(undefined, 'orderId', cart),
          revenue: get('grandTotalValue', cart),
          tax: get('totalVatValue', cart),
          shipping: get('shippingFeeValue', cart),
          coupon: getOr(undefined, 'campaignCode', cart),
        },
        products: map(
          (row) =>
            buildProductObjectFromOrderRow(
              row,
              getOr(undefined, 'quantity', row)
            ),
          getOr([], 'orderRows', cart)
        ),
      },
      userData: {
        email,
        phoneNumber: phone,
        address: {
          first_name: firstName,
          last_name: lastName,
          street: streetAddress,
          city,
          region: stateOrProvince || city,
          postal_code: zip,
          country,
        },
      },
    },
  });
};

export const adTractionEvent = (id, total, promo, md5) => {
  return sendEvent({
    transactionId: id,
    transactionTotal: total,
    transactionPromoCode: promo,
    md5,
  });
};
