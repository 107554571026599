import { all } from 'redux-saga/effects';
import { cartSaga } from './cart';
import { editorialSaga } from './editorial';
import { bootstrapSaga } from './bootstrap';
import { productSaga } from './products';
import { searchSaga } from './search';
import { productFilterSaga } from './productFilter';
import { menuSaga } from './menu';
import { giftSaga } from './gifts';
import { giftCardsSaga } from './giftCards';
import { marketsSaga } from './markets';
import { myPagesSaga } from './myPages';

export default function* rootSaga() {
  yield all([
    productSaga(),
    bootstrapSaga(),
    cartSaga(),
    editorialSaga(),
    searchSaga(),
    productFilterSaga(),
    menuSaga(),
    giftSaga(),
    giftCardsSaga(),
    marketsSaga(),
    myPagesSaga()
  ]);
}
