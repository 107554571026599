import { all, put, takeEvery, race, take, call, takeLatest } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { checkGiftCard, applyGiftCard, addGiftCard, removeGiftCard, fetchGiftCardSettings } from '../giftCards';

import {
  CHECK_GIFTCARD_REQUEST,
  ADD_GIFTCARD_TO_CART_REQUEST,
  APPLY_GIFTCARD_REQUEST,
  REMOVE_GIFTCARD_REQUEST,
  FETCH_GIFTCARD_SETTINGS_REQUEST
} from '../../store/actions/actionTypes';
import {
  checkGiftCardResponse,
  addGiftCardResponse,
  applyGiftCardResponse,
  addToCartResponse,
  removeGiftCardResponse,
  fetchGiftCardSettingsResponse
} from '../../store/actions/actions';

es6promise.polyfill();

function* handleGiftCardCheckRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(checkGiftCard, action.payload.data, action.payload.config),
      cancel: take(CHECK_GIFTCARD_REQUEST)
    });

    if (cancel) {
      yield put(checkGiftCardResponse({ status: 'CANCELED' })); // TODO: Test cancelation of requests when we have more data.
    }
    yield put(checkGiftCardResponse({ payload }));
  } catch (error) {
    yield put(checkGiftCardResponse({ error }));
  }
}

function* handleAddGiftCardRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(addGiftCard, action.payload.data, action.payload.config),
      cancel: take(ADD_GIFTCARD_TO_CART_REQUEST)
    });

    if (cancel) {
      yield put(addGiftCardResponse({ status: 'CANCELED' })); // TODO: Test cancelation of requests when we have more data.
    }
    yield put(addGiftCardResponse({ payload }));
    yield put(addToCartResponse({ payload }));
  } catch (error) {
    yield put(addGiftCardResponse({ error }));
  }
}

function* handleApplyGiftCardRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(applyGiftCard, action.payload.data, action.payload.config),
      cancel: take(APPLY_GIFTCARD_REQUEST)
    });

    if (cancel) {
      yield put(applyGiftCardResponse({ status: 'CANCELED' })); // TODO: Test cancelation of requests when we have more data.
    }
    yield put(applyGiftCardResponse({ payload }));
  } catch (error) {
    yield put(applyGiftCardResponse({ error }));
  }
}

function* handleRemoveGiftCardRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(removeGiftCard, action.payload.data, action.payload.config),
      cancel: take(REMOVE_GIFTCARD_REQUEST)
    });

    if (cancel) {
      yield put(removeGiftCardResponse({ status: 'CANCELED' })); // TODO: Test cancelation of requests when we have more data.
    }
    yield put(removeGiftCardResponse({ payload }));
  } catch (error) {
    yield put(removeGiftCardResponse({ error }));
  }
}

function* handleFetchGiftCardSettingsRequested(action) {
  try {
    const payload = yield call(fetchGiftCardSettings, action.payload);
    yield put(fetchGiftCardSettingsResponse({ payload }));
  } catch (error) {
    yield put(fetchGiftCardSettingsResponse({ error }));
  }
}

export const giftCardsSaga = function* giftSaga() {
  yield all([takeEvery(CHECK_GIFTCARD_REQUEST, handleGiftCardCheckRequested)]);
  yield all([takeEvery(ADD_GIFTCARD_TO_CART_REQUEST, handleAddGiftCardRequested)]);
  yield all([takeEvery(APPLY_GIFTCARD_REQUEST, handleApplyGiftCardRequested)]);
  yield all([takeEvery(REMOVE_GIFTCARD_REQUEST, handleRemoveGiftCardRequested)]);
  yield all(yield [takeLatest(FETCH_GIFTCARD_SETTINGS_REQUEST, handleFetchGiftCardSettingsRequested)]);
};
