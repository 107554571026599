import { merge, isEqual, set, get } from 'lodash/fp';
import { combineReducers } from 'redux';
import {
  GET_EDITORIAL_REQUEST,
  GET_EDITORIAL_RESPONSE,
  GET_EDITORIAL_STORES_REQUEST,
  GET_EDITORIAL_STORES_RESPONSE
} from '../actions/actionTypes';

function data(state = {}, { type, payload, error, meta }) {
  switch (type) {
    case GET_EDITORIAL_RESPONSE: {
      const next = set(meta.id, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    case GET_EDITORIAL_STORES_RESPONSE: {
      const next = set(meta.id, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

function request(state = {}, { type, error, meta }) {
  switch (type) {
    case GET_EDITORIAL_REQUEST: {
      const next = set(meta.id, { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_EDITORIAL_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(get('id', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    case GET_EDITORIAL_STORES_REQUEST: {
      const next = set(meta.id, { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_EDITORIAL_STORES_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(get('id', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

export const editorial = combineReducers({ data, request });
