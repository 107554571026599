import { CANCEL } from 'redux-saga';
import { CancelToken } from 'axios';
import { axiosSingleton } from './houdini';

export const login = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(`/authentication/login`, { ...payload }, { params: { ...params } })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const logout = (market) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(`/authentication/logout`, null, { params: { market } })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const createAccount = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(
      `/authentication/RegisterUser`,
      { ...payload },
      { params: { ...params } }
    )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const verifyEmail = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(
      `/authentication/VerifyEmail`,
      { ...payload },
      { params: { ...params } }
    )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const forgotPassword = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(
      `/authentication/forgotPassword`,
      { ...payload },
      { params: { ...params } }
    )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const resetPassword = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(
      `/authentication/resetPassword`,
      { ...payload },
      { params: { ...params } }
    )
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const getUser = (market) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .get(`/profile/userDetails`, { params: { market } })
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const setUser = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(`/profile/userDetails`, { ...payload }, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const getUserConsents = (market) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .get(`/profile/consents`, { params: { market } })
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const setUserConsents = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(`/profile/consents`, { ...payload }, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const changePassword = (payload, params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(`/profile/changePassword`, { ...payload }, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const deleteUser = (params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .post(`/profile/delete`, null, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch(({ response }) => {
      throw response?.data;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const getUserOrders = (params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .get(`/profile/orders`, { params: { ...params } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });

  request[CANCEL] = () => source.cancel();
  return request;
};

export const getUserOrderDetails = (params) => {
  const source = CancelToken.source();

  const request = axiosSingleton
    .get(`/profile/order`, { params: { ...params } })
    .then(({ data }) => data)
    .catch(({ response }) => response);

  request[CANCEL] = () => source.cancel();
  return request;
};
