import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import { getOr } from 'lodash/fp';
import es6promise from 'es6-promise';
import { fetchSearchResult } from '../houdini';

import { GET_SEARCH_REQUEST } from '../../store/actions/actionTypes';
import { searchResponse } from '../../store/actions/actions';

es6promise.polyfill();

function* handleSearchRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload, cancel } = yield race({
      payload: call(fetchSearchResult, meta),
      cancel: take(GET_SEARCH_REQUEST)
    });

    if (cancel) {
      yield put(searchResponse({ status: 'CANCELED' }));
    }

    yield put(searchResponse({ payload, meta }));
  } catch (error) {
    yield put(searchResponse({ error, meta }));
  }
}

export const searchSaga = function* searchSaga() {
  yield all([takeEvery(GET_SEARCH_REQUEST, handleSearchRequested)]);
};
