import { combineReducers } from 'redux';
import { get } from 'lodash/fp';
import { GET_LOGIN_REQUEST, GET_LOGIN_RESPONSE } from '../actions/actionTypes';
import { SUCCESS, LOADING, FAILURE } from '../../constants/requestStatuses';

function data(state = {}, { type, payload, error }) {
  switch (type) {
    case GET_LOGIN_RESPONSE: {
      return error || payload.data;
    }

    default:
      return state;
  }
}

const request = (state = {}, { type, error }) => {
  switch (type) {
    // case ADD_TO_CART_ERROR:
    //   return { status: ERROR_ADD_TO_CART, productId: payload, timestamp, message };

    case GET_LOGIN_REQUEST:
      return { status: LOADING };

    case GET_LOGIN_RESPONSE:
      return error
        ? { status: FAILURE, statusCode: get('response.status', error) }
        : { status: SUCCESS, statusCode: 200 };

    default:
      return state;
  }
};
export const myPages = combineReducers({ data, request });
