import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { getOr } from 'lodash/fp';
import { fetchProduct, fetchProducts } from '../products';

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCT_REQUEST,
  GET_PRODUCTS_FROM_CATEGORY_REQUEST,
  GET_PRODUCTS_FROM_LIST_REQUEST
} from '../../store/actions/actionTypes';
import {
  productResponse,
  productsResponse,
  productsFromCategoryResponse,
  productsFromListResponse
} from '../../store/actions/actions';
// import { CANCELED } from '../constants/requestStatuses';

es6promise.polyfill();

function* handleProductRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload, cancel } = yield race({
      payload: call(fetchProduct, meta),
      cancel: take(GET_PRODUCT_REQUEST)
    });

    if (cancel) {
      // yield put(productResponse({ error: CANCELED, meta }));
    } else {
      yield put(productResponse({ payload, meta }));
    }
  } catch (error) {
    yield put(productResponse({ error, meta }));
  }
}

function* handleProductsRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload, cancel } = yield race({
      payload: call(fetchProducts, meta),
      cancel: take(GET_PRODUCTS_REQUEST)
    });

    if (cancel) {
      // yield put(productsResponse({ error: CANCELED, meta }));
    } else {
      yield put(productsResponse({ payload, meta }));
    }
  } catch (error) {
    yield put(productsResponse({ error, meta }));
  }
}

function* handleProductsFromCategoryRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload } = yield race({
      payload: call(fetchProducts, meta)
    });

    yield put(productsFromCategoryResponse({ payload, meta }));
  } catch (error) {
    yield put(productsFromCategoryResponse({ error, meta }));
  }
}

function* handleProductsFromListRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload } = yield race({
      payload: call(fetchProducts, meta)
    });

    yield put(productsFromListResponse({ payload, meta }));
  } catch (error) {
    yield put(productsFromListResponse({ error, meta }));
  }
}

export const productSaga = function* productSaga() {
  yield all([
    takeEvery(GET_PRODUCT_REQUEST, handleProductRequested),
    takeEvery(GET_PRODUCTS_REQUEST, handleProductsRequested),
    takeEvery(GET_PRODUCTS_FROM_CATEGORY_REQUEST, handleProductsFromCategoryRequested),
    takeEvery(GET_PRODUCTS_FROM_LIST_REQUEST, handleProductsFromListRequested)
  ]);
};
