import { combineReducers } from 'redux';
import { get } from 'lodash/fp';
import {
  CHECK_GIFTCARD_REQUEST,
  CHECK_GIFTCARD_RESPONSE,
  ADD_GIFTCARD_TO_CART_REQUEST,
  ADD_GIFTCARD_TO_CART_RESPONSE,
  APPLY_GIFTCARD_REQUEST,
  APPLY_GIFTCARD_RESPONSE,
  REMOVE_GIFTCARD_REQUEST,
  REMOVE_GIFTCARD_RESPONSE,
  FETCH_GIFTCARD_SETTINGS_REQUEST,
  FETCH_GIFTCARD_SETTINGS_RESPONSE
} from '../actions/actionTypes';
import {
  ERROR_GIFT_CARD_CHECK,
  FAILURE,
  LOADING,
  LOADING_GIFT_CARD_CHECK,
  SUCCESS,
  SUCCESS_GIFT_CARD_CHECK
} from '../../constants/requestStatuses';

export function data(state = {}, { type, payload, error }) {
  switch (type) {
    case CHECK_GIFTCARD_RESPONSE: {
      return error ? { ...state, status: null } : { ...state, status: payload };
    }

    case ADD_GIFTCARD_TO_CART_RESPONSE: {
      return error ? { ...state, error: error.response.data } : { ...state, payload, error: [] };
    }

    case APPLY_GIFTCARD_RESPONSE: {
      return error ? { ...state, error: error.response.data } : { ...state, status: payload, error: [] };
    }

    case REMOVE_GIFTCARD_RESPONSE: {
      return error ? state : payload;
    }

    case FETCH_GIFTCARD_SETTINGS_RESPONSE: {
      return error ? state : { ...state, settings: payload };
    }

    default:
      return state;
  }
}

const request = (state = {}, { type, error }) => {
  switch (type) {
    case ADD_GIFTCARD_TO_CART_REQUEST:
    case APPLY_GIFTCARD_REQUEST:
    case REMOVE_GIFTCARD_REQUEST:
    case FETCH_GIFTCARD_SETTINGS_REQUEST:
      return { status: LOADING };
    case CHECK_GIFTCARD_REQUEST:
      return { status: LOADING_GIFT_CARD_CHECK };
    case CHECK_GIFTCARD_RESPONSE:
      return error
        ? { status: ERROR_GIFT_CARD_CHECK, statusCode: get('response.status', error) }
        : { status: SUCCESS_GIFT_CARD_CHECK, statusCode: 200 };
    case ADD_GIFTCARD_TO_CART_RESPONSE:
    case APPLY_GIFTCARD_RESPONSE:
    case REMOVE_GIFTCARD_RESPONSE:
    case FETCH_GIFTCARD_SETTINGS_RESPONSE:
      return error
        ? { status: FAILURE, statusCode: get('response.status', error) }
        : { status: SUCCESS, statusCode: 200 };
    default:
      return state;
  }
};

export const giftCards = combineReducers({ data, request });
