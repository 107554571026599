import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import { base } from '../../styles/theme';
import { GlobalStyle } from '../../styles/global';
import { media, dynamicFontSize } from '../../styles/mixins';
import { fonts } from '../../styles/typography';

import { ICONS } from '../../constants/icons';

import { Icon } from '../Icon/Icon';
import { LogoSvg } from '../Icon/reactIcons';

const heading = {
  sv: 'Oops! Verkar som att Houdini Sportwears hemsida är nere för tillfället',
  en: "Oops! Looks like Houdini Sportswear's homepage is down at the moment"
};

const body = {
  sv:
    'Men oroa dig inte, vi panik-ringer redan till internet för att lösa problemet. Tack för ditt tålamod så ses vi snart här igen!',
  en:
    "Don't worry, we're already panic-calling the Internet to fix the issue. Thank you for your patience and see you back here soon!"
};

const socialMediaLinkList = [
  {
    id: 'facebook',
    url: 'https://www.facebook.com/HoudiniSportswear/'
  },
  {
    id: 'instagram',
    url: 'https://www.instagram.com/houdinisportswear/'
  },
  {
    id: 'twitter',
    url: 'https://twitter.com/houdinisports'
  },
  {
    id: 'youtube',
    url: 'https://www.youtube.com/channel/UClS4VifINvcgZpnyXU3S41Q'
  },
  {
    id: 'linkedin',
    url: 'https://www.linkedin.com/company/houdini-sportswear/'
  }
];

export const NoAPIErrorPage = ({ marketLanguage }) => {
  const language = marketLanguage === 'sv' ? 'sv' : 'en';
  return (
    <ThemeProvider theme={base}>
      <GlobalStyle fontFamily="neo-sans" fontWeight="400" fontStyle="normal" />
      <Head>
        <title>{`${heading[language]} | Houdini Sportswear`}</title>
      </Head>

      <Container>
        <LogoLink href="/">
          <LogoSvg />
        </LogoLink>
        <Heading>{heading[language]}</Heading>
        <Body>{body[language]}</Body>
        <SocialMediaLinkList>
          {socialMediaLinkList.map(({ id, url }) => (
            <SocialMediaLinkListItem key={`no-api-social-media-link-list_${id}`}>
              <SocialMediaLinkListLink href={url} target="_blank" rel="noreferrer">
                <Icon icon={ICONS[id.toUpperCase()]} size={14} color="#ffffff" />
              </SocialMediaLinkListLink>
            </SocialMediaLinkListItem>
          ))}
        </SocialMediaLinkList>
      </Container>
    </ThemeProvider>
  );
};

NoAPIErrorPage.defaultProps = {
  marketLanguage: null
};

NoAPIErrorPage.propTypes = {
  marketLanguage: PropTypes.string
};

export const Container = styled.div`
  max-width: ${750 + 20 * 2}px;
  margin: 0 auto;
  padding: 40px 20px;
  ${media.M`
    max-width: ${750 + 30 * 2}px;
    padding: 60px 30px;
  `};
  ${media.L`
    max-width: ${750 + 40 * 2}px;
    padding: 80px 40px;
  `};
`;

export const LogoLink = styled.a`
  display: block;
  max-width: 160px;
  margin: 0 auto 40px;
  ${media.M`
    margin: 0 auto 60px;
  `};
  ${media.L`
    margin: 0 auto 80px;
  `};
`;

const Heading = styled.h1`
  margin: 0 0 40px;
  color: ${props => props.theme.color.black};
  ${fonts.GoudyOldStyleRegular};
  ${dynamicFontSize(28, 35)};
  line-height: 1.14;
  letter-spacing: -0.2px;
  text-align: center;
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 160px;
    margin: 20px auto 0;
    background-color: ${props => props.theme.color.superGrey};
  }
`;

const Body = styled.p`
  margin: 0 0 40px;
  color: ${props => props.theme.color.black};
  ${fonts.GoudyOldStyleRegular};
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

export const SocialMediaLinkList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 -10px -10px 0;
  padding: 0;
`;

export const SocialMediaLinkListItem = styled.li`
  padding: 0 10px 10px 0;
`;

export const SocialMediaLinkListLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #cabfb1;
  border: 1px solid #cabfb1;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  &:hover {
    background-color: transparent;
    > svg {
      > path {
        fill: #cabfb1 !important;
      }
    }
  }
  > svg {
    height: 15px;
    width: 20px;
    > path {
      fill: #ffffff !important;
      transition: fill 0.1s ease-in-out;
    }
  }
`;
