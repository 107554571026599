import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';
import { mapToObject } from '../helpers/mapToObject';
import { axiosSingleton } from './houdini';

export const fetchProduct = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/products/product`, { params: payload })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchProducts = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/products/list`, { params: payload })
    .then(({ data }) => (data.items ? mapToObject(data.items) : mapToObject(data)))
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchProductFilter = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/products/productFilter`, { params: payload })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const setProductSizeNotification = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/products/productFilter`, { params: payload })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const setEventTracking = async params => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/eventTracking/referral`, {}, { params })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};
