import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { fetchGifts } from '../cart';

import { GET_GIFTS_REQUEST } from '../../store/actions/actionTypes';
import { giftsResponse } from '../../store/actions/actions';

es6promise.polyfill();

function* handleGiftsRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(fetchGifts, action.payload),
      cancel: take(GET_GIFTS_REQUEST)
    });

    if (cancel) {
      yield put(giftsResponse({ status: 'CANCELED' })); // TODO: Test cancelation of requests when we have more data.
    }
    yield put(giftsResponse({ payload }));
  } catch (error) {
    yield put(giftsResponse({ error }));
  }
}

export const giftSaga = function* giftSaga() {
  yield all([takeEvery(GET_GIFTS_REQUEST, handleGiftsRequested)]);
};
