import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { fetchMenu } from '../houdini';

import { GET_MENU_REQUEST } from '../../store/actions/actionTypes';
import { menuResponse } from '../../store/actions/actions';

es6promise.polyfill();

function* handleMenuRequested(action) {
  try {
    const { payload, cancel } = yield race({
      payload: call(fetchMenu, action.payload),
      cancel: take(GET_MENU_REQUEST)
    });

    if (cancel) {
      yield put(menuResponse({ status: 'CANCELED' }));
    }
    yield put(menuResponse({ payload }));
  } catch (error) {
    yield put(menuResponse({ error }));
  }
}

export const menuSaga = function* menuSaga() {
  yield all([takeEvery(GET_MENU_REQUEST, handleMenuRequested)]);
};
