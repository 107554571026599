export const CART = 'CART';
export const NEWSLETTER = 'NEWSLETTER';
export const MARKET_SELECT = 'MARKET_SELECT';
export const MOBILE_MENU = 'MOBILE_MENU';
export const SEARCH = 'SEARCH';
export const MOBILE_FILTERS = 'MOBILE_FILTERS';
export const LARGE_PRODUCT_IMAGE = 'LARGE_PRODUCT_IMAGE';
export const GIFT = 'GIFT';
export const LOGIN = 'LOGIN';
export const LOGIN_PWD_RESET = 'LOGIN_PWD_RESET'; // for password updated message
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'; // MAYBE?
export const RESET_PASSWORD = 'RESET_PASSWORD';
