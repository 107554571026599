import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createWaitForMiddleware from 'redux-wait-for-ssr';

import { connect as cn } from 'react-redux';
import rootReducer from './reducers/rootReducer';
import rootSaga from '../api/sagas/rootSaga';

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension'); // eslint-disable-line
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const waitForMiddleware = createWaitForMiddleware().middleware;

  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware, waitForMiddleware])
  );

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  store.runSagaTask();
  return store;
}

function mapDispatchToProps(actions, dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default configureStore;

export const connect = (component, actions = {}, mapStateToProps) =>
  cn(mapStateToProps, mapDispatchToProps.bind(this, actions))(component);
