export const ICONS = {
  BAG: {
    viewBox: '0 0 20 20',
    paths: [
      'M1 19l.867-13h4.064v2h1V6h6v2h1V6h4.065l.866 13H1zM6.931 3.475C6.931 2.11 8.277 1 9.931 1s3 1.11 3 2.475V5h-6V3.475zm12.063 2.459A1.001 1.001 0 0 0 17.996 5h-4.065V3.475c0-1.919-1.79-3.475-4-3.475-2.209 0-4 1.556-4 3.475V5H1.867a1 1 0 0 0-.998.934l-.867 13A1 1 0 0 0 1 20h17.862a1 1 0 0 0 .998-1.066l-.866-13z'
    ]
  },
  CLOSE: {
    viewBox: '0 0 16 16',
    paths: [
      'M15.81,14.84,9,8l6.84-6.83a.69.69,0,0,0-1-1L8,7,1.16.2a.67.67,0,0,0-1,0,.68.68,0,0,0,0,1L7,8,.2,14.84a.7.7,0,0,0,0,1h0a.7.7,0,0,0,1,0L8,9l6.84,6.84a.69.69,0,0,0,1-1h0'
    ]
  },
  CLOSE_SMALL: {
    viewBox: '0 0 8 8',
    paths: [
      'M7.82,7l-3-3,3-3a.59.59,0,0,0,0-.85A.59.59,0,0,0,7,.18L4,3.12,1,.18a.59.59,0,0,0-.85,0A.59.59,0,0,0,.18,1l3,3-3,3a.59.59,0,0,0,0,.85.6.6,0,0,0,.86,0L4,4.88,7,7.82A.6.6,0,0,0,7.82,7'
    ]
  },
  SEARCH: {
    viewBox: '0 0 20 20',
    paths: [
      'M12.495 11.605a6.337 6.337 0 0 1-.89.889 6.302 6.302 0 0 1-4.007 1.435 6.338 6.338 0 0 1-6.331-6.332 6.337 6.337 0 0 1 6.331-6.33 6.338 6.338 0 0 1 6.333 6.33 6.3 6.3 0 0 1-1.436 4.008m7.322 7.324l-6.424-6.425a7.558 7.558 0 0 0 1.802-4.907A7.597 7.597 0 1 0 0 7.597a7.6 7.6 0 0 0 7.598 7.599 7.567 7.567 0 0 0 4.908-1.804l6.424 6.425a.629.629 0 0 0 .887-.888'
    ]
  },
  ENLARGE: {
    viewBox: '0 0 20 20',
    paths: [
      'M10.339 6.97H8.22V4.852a.605.605 0 0 0-.603-.603h-.05a.605.605 0 0 0-.603.603V6.97H4.847a.603.603 0 0 0-.602.603v.05c0 .331.27.603.602.603h2.118v2.118c0 .331.27.603.603.603h.05a.605.605 0 0 0 .603-.603V8.226h2.118a.606.606 0 0 0 .604-.603v-.05a.605.605 0 0 0-.604-.603',
      'M12.495 11.606a6.27 6.27 0 0 1-.89.888 6.296 6.296 0 0 1-4.007 1.436 6.338 6.338 0 0 1-6.331-6.332 6.337 6.337 0 0 1 6.33-6.331 6.34 6.34 0 0 1 6.334 6.33 6.3 6.3 0 0 1-1.436 4.009m7.323 7.324l-6.424-6.425A7.596 7.596 0 0 0 7.598 0a7.6 7.6 0 0 0 0 15.196 7.571 7.571 0 0 0 4.908-1.803l6.424 6.424a.628.628 0 0 0 .888-.888'
    ]
  },
  ARROW: {
    viewBox: '0 0 15 8',
    paths: [
      'M0 7.4a.6.6 0 0 1 .178-.426L7.069.176a.615.615 0 0 1 .862 0l6.891 6.798a.595.595 0 0 1 0 .85.615.615 0 0 1-.862 0L7.5 1.451 1.04 7.824a.615.615 0 0 1-.862 0A.598.598 0 0 1 0 7.4'
    ]
  },
  ARROWLEFT: {
    viewBox: '0 0 6 12',
    paths: [
      'M5.528 11.999a.435.435 0 0 1-.298-.144L.136 6.344A.497.497 0 0 1 0 6c0-.13.049-.254.136-.344L5.23.145A.427.427 0 0 1 5.547 0c.12 0 .234.052.317.145.087.09.136.214.136.344 0 .13-.049.253-.136.343L1.084 6l4.758 5.199c.087.09.136.214.136.344 0 .13-.05.254-.136.344-.087.08-.2.12-.314.112'
    ]
  },
  ARROWRIGHT: {
    viewBox: '0 0 8 15',
    paths: [
      'M.629.001c.15.008.293.072.398.18l6.792 6.89a.601.601 0 0 1 0 .86l-6.792 6.888a.586.586 0 0 1-.846 0 .601.601 0 0 1 0-.86L6.554 7.5.211 1.001a.601.601 0 0 1 0-.86c.115-.1.266-.15.418-.14'
    ]
  },
  PLAY: {
    viewBox: '0 0 56 56',
    paths: [
      'M28 1C13.112 1 1 13.112 1 28s12.112 27 27 27 27-12.112 27-27S42.888 1 28 1m0 55C12.561 56 0 43.439 0 28S12.561 0 28 0s28 12.561 28 28-12.561 28-28 28',
      'M22.694 20.387v15l13-7.5-13-7.5'
    ]
  },
  TRASH: {
    viewBox: '0 0 13 15',
    paths: [
      'M6.46 12.84a.48.48 0 0 0 .48-.46V4.93a.45.45 0 0 0-.44-.47.46.46 0 0 0-.44.47v7.45a.42.42 0 0 0 .4.46zM8.21 12.84a.48.48 0 0 0 .5-.44l.4-7.4a.47.47 0 0 0-.42-.49.45.45 0 0 0-.47.44l-.39 7.44a.43.43 0 0 0 .38.45zM4.75 12.84a.46.46 0 0 0 .42-.5l-.39-7.43a.48.48 0 0 0-.5-.44.44.44 0 0 0-.39.53l.4 7.44a.45.45 0 0 0 .46.4z',
      'M12.56 2.29H9.31v-1A1.29 1.29 0 0 0 8 0H5a1.29 1.29 0 0 0-1.31 1.31v1H.44a.45.45 0 0 0-.44.44.46.46 0 0 0 .44.47h1.19l.63 10.42A1.42 1.42 0 0 0 3.64 15h5.72a1.42 1.42 0 0 0 1.38-1.35l.64-10.43h1.18a.42.42 0 0 0 .44-.43.49.49 0 0 0-.44-.5zm-7.93-1A.33.33 0 0 1 5 1h3a.33.33 0 0 1 .33.33v1h-3.7V1.29zm5.23 12.3a.51.51 0 0 1-.5.48H3.64a.5.5 0 0 1-.49-.49L2.52 3.22h8l-.66 10.37z'
    ]
  },
  CART: {
    viewBox: '0 0 20 20',
    paths: [
      'M19.122 5.936a1.004 1.004 0 0 0-1.007-.93h-4.088v-1.53c-.16-2.055-1.955-3.6-4.027-3.468C7.928-.124 6.134 1.42 5.973 3.476v1.53H1.885c-.53-.001-.97.405-1.007.93L.002 18.93c-.02.277.078.55.27.753.19.203.458.318.737.317h17.982c.28 0 .547-.114.738-.317a.995.995 0 0 0 .269-.753l-.876-12.994zM6.98 3.476A2.794 2.794 0 0 1 10 1.007a2.794 2.794 0 0 1 3.02 2.47v1.529H6.98v-1.53zM1.01 19l.875-12.995h4.088v2H6.98v-2h6.04v2h1.007v-2h4.088L18.99 19H1.009z'
    ]
  },
  ARROWDOWN: {
    viewBox: '0 0 15 8',
    paths: [
      'M15 .602a.6.6 0 0 1-.18.43l-6.89 6.79a.6.6 0 0 1-.86 0L.18 1.002a.59.59 0 0 1 0-.85.6.6 0 0 1 .82.03l6.5 6.37L14 .182a.6.6 0 0 1 .86 0 .576.576 0 0 1 .14.42'
    ]
  },
  FACEBOOK: {
    viewBox: '0 0 8 14',
    paths: [
      'M4.7 14V7.6h2.1l.3-2.5H4.7V3.5c0-.7.2-1.2 1.2-1.2h1.3V.1C7 .1 6.3 0 5.3 0 3.4 0 2.1 1.2 2.1 3.3v1.8H0v2.5h2.1V14h2.6z'
    ]
  },
  INSTAGRAM: {
    viewBox: '0 0 14 14',
    paths: [
      'M7 1.3h2.8c.7 0 1.1.1 1.3.2.3.1.6.3.8.5.2.2.4.5.5.8.1.2.2.6.2 1.3v5.6c0 .7-.1 1.1-.2 1.3-.1.3-.3.6-.5.8-.2.2-.5.4-.8.5-.2.1-.6.2-1.3.2H4.2c-.7 0-1.1-.1-1.3-.2-.3-.1-.6-.3-.8-.5-.2-.2-.4-.5-.5-.8-.1-.2-.2-.6-.2-1.3V4.1c0-.7.1-1.1.2-1.3.1-.3.3-.6.5-.8.2-.2.5-.4.8-.5.2-.1.6-.2 1.3-.2H7zM7 0H4.1c-.7 0-1.3.2-1.7.3-.4.2-.8.5-1.2.9-.4.4-.7.8-.8 1.2-.2.5-.3 1-.4 1.7v5.8c0 .7.2 1.3.3 1.7.2.5.4.9.8 1.2.4.4.8.6 1.2.8.4.2 1 .3 1.7.3h5.8c.7 0 1.3-.2 1.7-.3.5-.2.9-.4 1.2-.8.4-.4.6-.8.8-1.2.2-.4.3-1 .3-1.7V4.1c0-.7-.2-1.3-.3-1.7-.2-.5-.4-.9-.8-1.2-.4-.4-.8-.6-1.2-.8-.4-.2-1-.3-1.7-.3C9.1 0 8.9 0 7 0z',
      'M7 3.4C5 3.4 3.4 5 3.4 7S5 10.6 7 10.6 10.6 9 10.6 7 9 3.4 7 3.4zm0 5.9c-1.3 0-2.3-1-2.3-2.3 0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3 0 1.3-1 2.3-2.3 2.3z'
    ]
  },
  TWITTER: {
    viewBox: '0 0 18 14',
    paths: [
      'M5.4 14c6.5 0 10.1-5.4 10.1-10.1v-.5c.7-.5 1.3-1.1 1.8-1.8-.6.3-1.3.5-2 .6.7-.4 1.3-1.1 1.6-2-.7.4-1.4.7-2.2.9C13.9.4 12.9 0 11.9 0c-2 0-3.5 1.6-3.5 3.5 0 .3 0 .5.1.8-3-.1-5.6-1.5-7.3-3.7-.3.6-.5 1.2-.5 1.8 0 1.2.6 2.3 1.6 2.9-.6 0-1.1-.2-1.6-.4C.7 6.6 1.9 8 3.5 8.4c-.3.1-.6.2-.9.2-.2 0-.4 0-.7-.1.4 1.4 1.8 2.4 3.3 2.5-1.2.9-2.7 1.5-4.4 1.5H0c1.6.9 3.4 1.5 5.4 1.5'
    ]
  },
  YOUTUBE: {
    viewBox: '0 0 20 14',
    paths: [
      'M19.6 2.2c-.2-.9-.9-1.5-1.8-1.8C16.3 0 10 0 10 0S3.7 0 2.2.4C1.3.6.6 1.3.4 2.2 0 3.7 0 7 0 7s0 3.3.4 4.8c.2.9.9 1.5 1.8 1.8 1.5.4 7.8.4 7.8.4s6.3 0 7.8-.4c.9-.2 1.5-.9 1.8-1.8.4-1.5.4-4.8.4-4.8s0-3.3-.4-4.8zM13.2 7L8 10V4l5.2 3z'
    ]
  },
  GOOGLEPLUS: {
    viewBox: '0 0 21 14',
    paths: [
      'M.875 7C.787 3.85 3.587.963 6.737.875 8.4.7 9.976 1.4 11.2 2.362c-.525.613-1.05 1.138-1.575 1.663-1.137-.7-2.362-1.138-3.675-.7-2.013.525-3.238 2.975-2.538 4.9.613 2.1 3.15 3.238 5.163 2.363 1.05-.35 1.75-1.313 2.013-2.275h-3.5v-2.1h5.95c.087 1.662-.175 3.5-1.226 4.9-1.487 1.925-4.374 2.537-6.65 1.75C2.625 11.988.787 9.538.875 7zM16.8 4.375h1.75v1.75h1.75v1.75h-1.75v1.75H16.8v-1.75h-1.75v-1.75h1.75v-1.75z'
    ]
  },
  PINTEREST: {
    viewBox: '0 0 11 14',
    paths: [
      'M4.5 9.3c-.4 1.9-.8 3.7-2.2 4.7-.4-2.9.6-5.1 1.1-7.4-.8-1.4.1-4.1 1.8-3.4 2.1.8-1.8 5 .8 5.5 2.7.6 3.8-4.7 2.1-6.4C5.7-.2 1 2.2 1.6 5.8c.1.9 1 1.1.4 2.3-1.6-.3-2-1.6-2-3.2C.1 2.2 2.4.3 4.7 0c2.9-.3 5.7 1.1 6.1 3.8.4 3.1-1.3 6.5-4.5 6.3-.8 0-1.2-.4-1.8-.8z'
    ]
  },
  LINKEDIN: {
    viewBox: '0 0 14.03 14',
    paths: [
      'M3.14,14H.23V4.65H3.14ZM1.69,3.37A1.69,1.69,0,1,1,3.37,1.69,1.68,1.68,0,0,1,1.69,3.37ZM14,14H11.12V9.45c0-1.08,0-2.48-1.51-2.48S7.87,8.15,7.87,9.37V14H5V4.65H7.75V5.93h0a3,3,0,0,1,2.75-1.51C13.48,4.42,14,6.35,14,8.87Z'
    ]
  },
  POSTNORD: {
    viewBox: '0 0 100 18',
    paths: [
      'M23.7,9.1c0-1.3-1.1-2.3-2.4-2.3c-1.3,0-2.4,1-2.4,2.3c0,0.6,0.2,1.2,0.7,1.7c0.5,0.4,1.1,0.7,1.7,0.6c0.6,0,1.3-0.2,1.7-0.6C23.5,10.4,23.8,9.8,23.7,9.1L23.7,9.1z M28.4,9c0,4.3-3.6,6.1-7.1,6.1s-6.9-1.8-6.9-5.9c0-4.2,3.6-6.1,7.1-6.1C24.9,3.1,28.4,4.9,28.4,9z M35.5,7.6c-1.4-0.2-2-0.2-2-0.7c0-0.5,0.6-0.6,1.7-0.6c1.2,0,2.4,0.2,3.6,0.6l0.8-3.2c-1.4-0.3-2.9-0.5-4.3-0.5c-4.2,0-6.4,1.5-6.4,4c0,1.9,1.2,3,4.6,3.5c1.5,0.2,2,0.3,2,0.8c0,0.5-0.6,0.7-1.6,0.7c-1.5,0-2.9-0.3-4.2-0.9l-0.8,3.2c1.5,0.5,3.1,0.7,4.7,0.7c4.5,0,6.5-1.5,6.5-4C40,9.1,38.9,8.1,35.5,7.6z M45.2,1h-4.4v13.8h4.3V7h2.1l2-3.5h-4V1z M57.4,3.1c-2.5,0-3.5,1.5-3.5,1.5V3.5h-4.3v11.3h4.3V9c0-1.4,0.6-2.1,1.9-2.1c1.3,0,1.5,1.1,1.5,2.4v5.5h4.4l0-6.9C61.8,4.7,60.2,3.1,57.4,3.1z M95.8,9.1c0-0.6-0.2-1.2-0.7-1.7C94.6,7,94,6.8,93.4,6.8c-0.6,0-1.3,0.2-1.7,0.7c-0.4,0.4-0.7,1-0.6,1.6c-0.1,0.9,0.3,1.7,1.1,2.2c0.8,0.5,1.8,0.5,2.6,0C95.5,10.8,95.9,10,95.8,9.1L95.8,9.1z M100,0v14.8h-4.3v-1.1c-0.9,1-2.2,1.5-3.6,1.5c-3.6,0-5.6-2.7-5.6-6c0-3.3,2.3-6,6-6c1.2-0.1,2.4,0.3,3.3,1.1V0.8L100,0z M82.2,9.3c0-1.7,1.1-2.2,2.5-2.2c0.5,0,1,0.1,1.5,0.2l0.1-3.8c-0.3-0.1-0.7-0.1-1-0.1c-2.4,0-3.1,1.5-3.1,1.5V3.5h-4.4v11.3h4.4L82.2,9.3L82.2,9.3z M9,9.1C9,8.5,8.8,7.9,8.4,7.4C7.9,7,7.3,6.7,6.6,6.8C6,6.7,5.3,7,4.9,7.4C4.4,7.9,4.2,8.5,4.2,9.1c0,0.6,0.2,1.2,0.7,1.7c0.5,0.4,1.1,0.7,1.7,0.7c0.6,0,1.3-0.2,1.7-0.6C8.8,10.4,9.1,9.8,9,9.1L9,9.1z M13.6,9.1c0,3.3-2.3,6-6,6c-1.2,0.1-2.4-0.3-3.3-1.1v4H0V3.5h4.4v1.1c0.9-1,2.2-1.5,3.6-1.5C11.6,3.1,13.6,5.8,13.6,9.1L13.6,9.1z M72.2,9.1c0-0.8-0.5-1.6-1.2-2c-0.7-0.4-1.7-0.4-2.4,0c-0.7,0.4-1.2,1.2-1.2,2c0,0.6,0.2,1.2,0.7,1.7s1.1,0.7,1.7,0.6c0.6,0,1.3-0.2,1.7-0.6C72,10.4,72.2,9.8,72.2,9.1L72.2,9.1z M76.8,9c0,4.3-3.6,6.1-7.1,6.1c-3.4,0-6.9-1.8-6.9-5.9c0-4.2,3.6-6.1,7.1-6.1S76.8,4.9,76.8,9z'
    ]
  },
  CAMERA: {
    viewBox: '0 0 24 24',
    paths: [
      'M1.6 4.80078C0.716 4.80078 0 5.51678 0 6.40078V17.6008C0 18.4848 0.716 19.2008 1.6 19.2008H15.2C16.084 19.2008 16.8 18.4848 16.8 17.6008V6.40078C16.8 5.51678 16.084 4.80078 15.2 4.80078H1.6ZM23.2 6.40078C22.9917 6.40078 22.7915 6.48207 22.6422 6.62734L18.4 10.4008V12.0008V13.6008L22.6594 17.3898L22.6719 17.4008C22.8178 17.5294 23.0055 17.6005 23.2 17.6008C23.4122 17.6008 23.6157 17.5165 23.7657 17.3665C23.9157 17.2164 24 17.013 24 16.8008V12.0008V7.20078C24 6.98861 23.9157 6.78513 23.7657 6.6351C23.6157 6.48507 23.4122 6.40078 23.2 6.40078Z'
    ]
  },
  VIDEO_PLAY: {
    viewBox: '0 0 72 76',
    filter: 'url(#filter0_d_905:18845)',
    paths: [
      'M36 4C18.3533 4 4 18.3533 4 36C4 53.6467 18.3533 68 36 68C53.6467 68 68 53.6467 68 36C68 18.3533 53.6467 4 36 4ZM27.6522 49.913V22.087L51.3043 36L27.6522 49.913Z'
    ],
    defs: `
      <filter xmlns="http://www.w3.org/2000/svg" id="filter0_d_905:18845" x="0.880005" y="2.88" width="74.24" height="74.24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_905:18845"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_905:18845" result="shape"/>
      </filter>
        `
  },
  VIDEO_PAUSE: {
    viewBox: '0 0 72 76',
    filter: 'url(#filter0_d_905:18847)',
    paths: [
      'M36 4C18.3533 4 4 18.3533 4 36C4 53.6467 18.3533 68 36 68C53.6467 68 68 53.6467 68 36C68 18.3533 53.6467 4 36 4ZM32.5081 48.5217H25.5516V23.4783H32.5081V48.5217ZM46.4484 48.5217H39.4919V23.4783H46.4484V48.5217Z'
    ],
    defs: `
      <filter xmlns="http://www.w3.org/2000/svg" id="filter0_d_905:18847" x="0.880005" y="2.88" width="74.24" height="74.24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_905:18847"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_905:18847" result="shape"/>
      </filter>
        `
  },
  INFO: {
    viewBox: '0 0 48 48',
    paths: [
      'M24 6.00012C14.0681 6.00012 6 14.0683 6 24.0001C6 33.932 14.0681 42.0001 24 42.0001C33.9319 42.0001 42 33.932 42 24.0001C42 14.0683 33.9319 6.00012 24 6.00012ZM24 7.56534C33.0859 7.56534 40.4348 14.9142 40.4348 24.0001C40.4348 33.0861 33.0859 40.4349 24 40.4349C14.9141 40.4349 7.56522 33.0861 7.56522 24.0001C7.56522 14.9142 14.9141 7.56534 24 7.56534Z',
      'M23.2174 14.6088V20.2175H24.7826V14.6088H23.2174Z',
      'M23.2174 22.7827V33.3914H24.7826V22.7827H23.2174Z'
    ]
  },
  HIDDEN: {
    viewBox: '0 0 24 24',
    paths: [
      'M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24'
    ]
  },
  VISIBLE: {
    viewBox: '0 0 28 28',
    paths: [
      'M17.7469 15.4149C17.9855 14.8742 18.1188 14.2724 18.1188 14.0016C18.1188 11.6544 16.2952 9.7513 14.046 9.7513C11.7969 9.7513 9.97332 11.6544 9.97332 14.0016C9.97332 16.3487 12.0097 17.8886 14.046 17.8886C15.3486 17.8886 16.508 17.2515 17.2517 16.2595C17.4466 16.0001 17.6137 15.7168 17.7469 15.4149ZM14.046 15.7635C14.5551 15.7635 15.0205 15.5684 15.3784 15.2457C15.81 14.8566 16 14.2807 16 14.0016C16 12.828 15.1716 11.8764 14.046 11.8764C12.9205 11.8764 12 12.8264 12 14C12 14.8104 12.9205 15.7635 14.046 15.7635Z',
      'M1.09212 14.2724C1.07621 14.2527 1.10803 14.2931 1.09212 14.2724C0.96764 14.1021 0.970773 13.8996 1.09268 13.7273C1.10161 13.7147 1.11071 13.7016 1.11993 13.6882C4.781 8.34319 9.32105 5.5 14.0142 5.5C18.7025 5.5 23.2385 8.33554 26.8956 13.6698C26.965 13.771 27 13.875 27 13.9995C27 14.1301 26.9593 14.2399 26.8863 14.3461C23.2302 19.6702 18.6982 22.5 14.0142 22.5C9.30912 22.5 4.75717 19.6433 1.09212 14.2724ZM3.93909 13.3525C3.6381 13.7267 3.6381 14.2722 3.93908 14.6465C7.07417 18.5443 10.6042 20.3749 14.0142 20.3749C17.4243 20.3749 20.9543 18.5443 24.0894 14.6465C24.3904 14.2722 24.3904 13.7267 24.0894 13.3525C20.9543 9.45475 17.4243 7.62513 14.0142 7.62513C10.6042 7.62513 7.07417 9.45475 3.93909 13.3525Z'
    ]
  },
  USER: {
    viewBox: '0 0 18 18',
    paths: [
      'M2.03973 15.5634C2.93896 14.5511 5.09634 11.7002 9.07449 11.7002C13.1822 11.7002 15.2586 14.5506 16.116 15.5691C16.216 15.6879 16.2008 15.8653 16.0819 15.9654C15.9631 16.0654 15.7857 16.0502 15.6856 15.9313C14.9595 15.0687 13.0581 12.2627 9.07449 12.2627C5.22437 12.2627 3.23281 15.0673 2.46027 15.937C2.35712 16.0531 2.17935 16.0636 2.06322 15.9605C1.94709 15.8573 1.93658 15.6796 2.03973 15.5634Z'
    ],
    circles: [
      {
        cx: 9,
        cy: 6,
        r: 3.35,
        stroke: 'black',
        strokeWidth: 0.8
      }
    ]
  }
};
