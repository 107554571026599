import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';
import { axiosSingleton } from './houdini';
import { ingridSuspend, ingridResume } from '../helpers/ingrid';
import { klarnaSuspend, klarnaResume } from '../helpers/klarna';

export const suspendCheckout = (updatePaymentProvider, isAdyen) => {
  if (updatePaymentProvider) {
    ingridSuspend();
    if (!isAdyen) {
      klarnaSuspend();
    }
  }
};

export const resumeCheckout = (updatePaymentProvider, isAdyen) => {
  if (updatePaymentProvider) {
    ingridResume();
    if (!isAdyen) {
      klarnaResume();
    }
  }
};

export const fetchCart = params => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/cart/get/`, { params: { ...params } })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const addToCart = (payload, params, isAdyen) => {
  const { updatePaymentProvider } = params;
  suspendCheckout(updatePaymentProvider, isAdyen);

  const headers = {
    'Content-Type': 'application/json'
  };
  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/cart/add`, { size: { articleId: payload } }, { headers, params })
    .then(response => {
      resumeCheckout(updatePaymentProvider, isAdyen);
      return response.data;
    })
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const subtractFromCart = (payload, params, isAdyen) => {
  const { updatePaymentProvider } = params;
  suspendCheckout(updatePaymentProvider, isAdyen);

  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/cart/subtractItem`, { orderRowId: payload }, { params })
    .then(response => {
      resumeCheckout(updatePaymentProvider, isAdyen);
      return response.data;
    })
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const removeFromCart = (payload, params, isAdyen) => {
  const { updatePaymentProvider } = params;
  suspendCheckout(updatePaymentProvider, isAdyen);

  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/cart/removeItem`, { orderRowId: payload }, { params })
    .then(response => {
      resumeCheckout(updatePaymentProvider, isAdyen);
      return response.data;
    })
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchGifts = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/checkoutOffer/products`, { params: payload })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

// NOT IN USE
export const clearCart = () => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/cart/clear`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const setCartCountry = params => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/cart/setCountry`, {}, { params })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};
