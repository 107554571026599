import React from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash/fp';

import { color } from '../../styles/variables';

export const Icon = (props) => {
  if (!size(props.icon)) return null;

  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: `${props.mr || 0}px`,
    },
    path: {
      fill: props.color || 'currentColor',
    },
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={styles.svg}
      width={`${props.width ? props.width : props.size}px`}
      height={`${props.height ? props.height : props.size}px`}
      viewBox={props.icon.viewBox}
      className={props.className}
      fill={props.svgFill || 'none'}
      onClick={props.onClick ? props.onClick : () => {}}
    >
      {props.icon.clipPath && props.icon.filter && (
        <g clipPath={props.icon.clipPath}>
          <g filter={props.icon.filter}>
            {props.icon.paths.map((path) => (
              <path key={path} style={styles.path} d={path} />
            ))}
          </g>
        </g>
      )}

      {props.icon.clipPath && !props.icon.filter && (
        <g clipPath={props.icon.clipPath}>
          {props.icon.paths.map((path) => (
            <path key={path} style={styles.path} d={path} />
          ))}
        </g>
      )}

      {props.icon.filter && !props.icon.clipPath && (
        <g filter={props.icon.filter}>
          {props.icon.paths.map((path) => (
            <path key={path} style={styles.path} d={path} />
          ))}
        </g>
      )}

      {(!props.icon.filter || !props.icon.clipPath) &&
        props.icon.paths.map((path) => (
          <path
            key={path}
            style={styles.path}
            d={path}
            {...props.pathSettings}
          />
        ))}

      {props.icon.circles &&
        props.icon.circles.map(({ cx, cy, r, stroke, strokeWidth }, idx) => (
          <circle
            key={idx}
            cx={cx}
            cy={cy}
            r={r}
            stroke={stroke || 'black'}
            strokeWidth={strokeWidth}
          />
        ))}

      {props.icon.defs && (
        <defs
          xmlns="http://www.w3.org/2000/svg"
          dangerouslySetInnerHTML={{ __html: props.icon.defs }}
        />
      )}
    </svg>
  );
};

Icon.defaultProps = {
  icon: null,
  size: 16,
  width: null,
  height: null,
  color: color.black,
  className: '',
  onClick: () => {},
  svgFill: 'none',
  pathSettings: {},
  mr: 0,
};

Icon.propTypes = {
  icon: PropTypes.shape({
    viewBox: PropTypes.string,
    paths: PropTypes.arrayOf(PropTypes.string).isRequired,
    circles: PropTypes.arrayOf(PropTypes.object),
    filter: PropTypes.string,
    clipPath: PropTypes.string,
    defs: PropTypes.string,
  }),
  className: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  svgFill: PropTypes.string,
  pathSettings: PropTypes.object,
  mr: PropTypes.number,
  onClick: PropTypes.func,
};
