import { waitFor } from 'redux-wait-for-ssr';
import { get, identity } from 'lodash/fp';
import { FAILURE } from '../constants/requestStatuses';

export async function setServerResponseStatusCode(context, waitForActions, storeLocation, id) {
  const { store } = context;

  const statusKeyPath = [storeLocation, 'request', id, 'status'].filter(identity);
  const statusCodeKeyPath = [storeLocation, 'request', id, 'statusCode'].filter(identity);

  try {
    // Wait for a response on the request.
    await store.dispatch(waitFor(waitForActions));

    // Get the updated store state.
    const updatedState = store.getState();

    // Get the updated request status.
    const updatedStatus = get(statusKeyPath, updatedState);
    const updatedStatusCode = get(statusCodeKeyPath, updatedState);

    // Set HTTP status code to 404 if the request failed.
    if (updatedStatus === FAILURE && context.res) {
      // eslint-disable-next-line no-param-reassign
      context.res.statusCode = updatedStatusCode || 404;
    }

    return updatedState;
  } catch (e) {
    console.log(`Failure, response: ${e}`);
    if (context.res) {
      // eslint-disable-next-line no-param-reassign
      context.res.statusCode = 500;
    }
    return null;
  }
}
