import { unset, set, isEqual } from 'lodash/fp';
import { SET_VIEWSTATE, REMOVE_VIEWSTATE } from '../actions/actionTypes';

export const viewState = (state = {}, { type, payload, error }) => {
  // TODO: Refactor how viewstate is saved and used!
  switch (type) {
    case SET_VIEWSTATE: {
      const next = set(payload.id, payload.value, state);
      return error || isEqual(next, state) ? state : next;
    }
    case REMOVE_VIEWSTATE: {
      const next = unset(payload.id, state);
      return error || isEqual(next, state) ? state : next;
    }
    default:
      return state;
  }
};
