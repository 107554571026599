import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { login } from '../myPages';

import { GET_LOGIN_REQUEST } from '../../store/actions/actionTypes';
import { loginResponse } from '../../store/actions/actions';

es6promise.polyfill();

function* handleLoginRequested(action) {
  const { meta } = action;
  const { market, formData } = meta;

  try {
    const { payload, cancel } = yield race({
      payload: call(login, formData, { market }),
      cancel: take(GET_LOGIN_REQUEST)
    });

    if (cancel) {
      yield put(loginResponse({ status: 'CANCELED' }));
    }

    yield put(loginResponse({ payload }));
  } catch (error) {
    yield put(loginResponse({ error }));
  }
}

export const myPagesSaga = function* myPagesSaga() {
  yield all([takeEvery(GET_LOGIN_REQUEST, handleLoginRequested)]);
};
