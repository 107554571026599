import { set, merge, isEqual, map, get } from 'lodash/fp';
import { combineReducers } from 'redux';
import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_RESPONSE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_RESPONSE,
  GET_PRODUCTS_FROM_CATEGORY_REQUEST,
  GET_PRODUCTS_FROM_CATEGORY_RESPONSE,
  GET_PRODUCTS_FROM_LIST_REQUEST,
  GET_PRODUCTS_FROM_LIST_RESPONSE,
  GET_SEARCH_RESPONSE
} from '../actions/actionTypes';

const mapObjectsToIds = payload => map(item => item.hit, get('products', payload));

export const mapSearchResultToObject = arr =>
  arr.reduce((memo, item) => {
    if (!item.id) {
      return memo;
    }
    return set(item.id, item, memo);
  }, {});

function data(state = {}, { type, payload, error, meta }) {
  switch (type) {
    case GET_PRODUCT_RESPONSE: {
      const productId = get('id', meta);
      const next = set(productId, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    case GET_PRODUCTS_RESPONSE: {
      const categoryId = get('id', meta);
      const next = set(categoryId, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    case GET_PRODUCTS_FROM_CATEGORY_RESPONSE: {
      const categoryId = get('categoryId', meta);
      const next = set(categoryId, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    case GET_PRODUCTS_FROM_LIST_RESPONSE: {
      const categoryId = get('productListId', meta);
      const next = set(categoryId, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    case GET_SEARCH_RESPONSE: {
      const mapObjToIds = mapObjectsToIds(payload);
      const mapToObj = mapSearchResultToObject(mapObjToIds);

      const next = merge(state, mapToObj);
      return error || isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

function request(state = {}, { type, error, meta }) {
  switch (type) {
    case GET_PRODUCT_REQUEST: {
      const next = set(meta.id, { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_PRODUCT_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(get('id', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    case GET_PRODUCTS_REQUEST: {
      const next = set(get('id', meta), { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_PRODUCTS_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(get('id', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    case GET_PRODUCTS_FROM_CATEGORY_REQUEST: {
      const next = set(get('categoryId', meta), { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_PRODUCTS_FROM_CATEGORY_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(get('categoryId', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    case GET_PRODUCTS_FROM_LIST_REQUEST: {
      const next = set(get('productListId', meta), { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_PRODUCTS_FROM_LIST_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(get('productListId', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

export const products = combineReducers({ data, request });
