import { set, merge, isEqual, get } from 'lodash/fp';
import { combineReducers } from 'redux';
import { GET_PRODUCT_FILTER_REQUEST, GET_PRODUCT_FILTER_RESPONSE } from '../actions/actionTypes';

const getID = meta => {
  return meta.id || meta.productListId || meta.categoryId;
};

function data(state = {}, { type, payload, error, meta }) {
  switch (type) {
    case GET_PRODUCT_FILTER_RESPONSE: {
      const id = getID(meta);
      const next = set(id, payload, state);
      return error || isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

function request(state = {}, { type, error, meta }) {
  switch (type) {
    case GET_PRODUCT_FILTER_REQUEST: {
      const next = set(getID(meta), { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_PRODUCT_FILTER_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: get(['response', 'status'], error)
      };

      const next = set(getID(meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

export const productFilter = combineReducers({ data, request });
