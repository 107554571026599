import { combineReducers } from 'redux';
import { get } from 'lodash/fp';
import { GET_GIFTS_RESPONSE, GET_GIFTS_REQUEST } from '../actions/actionTypes';
import { FAILURE, LOADING, SUCCESS } from '../../constants/requestStatuses';

export function data(state = {}, { type, payload, error }) {
  switch (type) {
    case GET_GIFTS_RESPONSE: {
      return error ? state : payload;
    }
    default:
      return state;
  }
}

const request = (state = {}, { type, error }) => {
  switch (type) {
    case GET_GIFTS_REQUEST:
      return { status: LOADING };
    case GET_GIFTS_RESPONSE:
      return error
        ? { status: FAILURE, statusCode: get('response.status', error) }
        : { status: SUCCESS, statusCode: 200 };
    default:
      return state;
  }
};

export const gifts = combineReducers({ data, request });
