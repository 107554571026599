import { css } from 'styled-components';
import { sizes } from './variables';

export const hover = (...args) => css`
  @media not all and (hover: none), screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:hover {
      ${css(...args)};
    }
  }
`;

export const touch = (...args) => css`
  @media not all and (hover: none) {
    ${css(...args)};
  }
`;

export const opacityHover = css`
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  transform: translateZ(0) scale(1, 1) !important;
  backface-visibility: hidden !important;

  @media not all and (hover: none) {
    &:hover {
      opacity: 0.45;
    }
  }
`;

export const media = {
  XS: (...args) => css`
    @media (min-width: ${sizes.xSmall}px) {
      ${css(...args)};
    }
  `,
  S: (...args) => css`
    @media (min-width: ${sizes.small}px) {
      ${css(...args)};
    }
  `,
  M: (...args) => css`
    @media (min-width: ${sizes.medium}px) {
      ${css(...args)};
    }
  `,
  L: (...args) => css`
    @media (min-width: ${sizes.large}px) {
      ${css(...args)};
    }
  `,
  XL: (...args) => css`
    @media (min-width: ${sizes.xLarge}px) {
      ${css(...args)};
    }
  `,
  XXL: (...args) => css`
    @media (min-width: ${sizes.xXLarge}px) {
      ${css(...args)};
    }
  `,
  PM: (...args) => css`
    @media (min-width: ${sizes.pageMaxWidth + 40}px) {
      ${css(...args)};
    }
  `,
  SBP: (...args) => css`
    @media (min-width: ${sizes.sliderBreakPoint}px) {
      ${css(...args)};
    }
  `,
  FBP: (...args) => css`
    @media (min-width: ${sizes.footerBreakPoint}px) {
      ${css(...args)};
    }
  `,
  HBP: (...args) => css`
    @media (min-width: ${sizes.headerBreakpoint}px) {
      ${css(...args)};
    }
  `,
  HBBP: (...args) => css`
    @media (min-width: ${sizes.HeroImageBlockBreakpoint}px) {
      ${css(...args)};
    }
  `,
  IE: (...args) => css`
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      ${css(...args)};
    }
  `
};

export const dynamicFontSize = (
  minFontSize,
  maxFontSize,
  minScreenWidth = sizes.xSmall,
  maxScreenWidth = sizes.large
) => `
    font-size: ${minFontSize}px;
      @media screen and (min-width: ${minScreenWidth}px) {
        font-size: calc(${minFontSize}px + (${maxFontSize} - ${minFontSize}) * ((100vw - ${minScreenWidth}px) / (${maxScreenWidth} - ${minScreenWidth}))) !important;
      }
      @media screen and (min-width: ${maxScreenWidth}px) {
        font-size: ${maxFontSize}px !important;
      }
  `;

export const dynamicPadding = (minPadding, maxPadding, minScreenWidth = sizes.xSmall, maxScreenWidth = sizes.large) => `
        padding-left: ${minPadding}px;
        padding-right: ${minPadding}px;

        @media screen and (min-width: ${minScreenWidth}px) {
          padding-left: calc(${minPadding}px + (${maxPadding} - ${minPadding}) * ((100vw - ${minScreenWidth}px) / (${maxScreenWidth} - ${minScreenWidth})));
          padding-right: calc(${minPadding}px + (${maxPadding} - ${minPadding}) * ((100vw - ${minScreenWidth}px) / (${maxScreenWidth} - ${minScreenWidth})));
        }
        
        @media screen and (min-width: ${maxScreenWidth}px) {
          padding-left: ${maxPadding}px;
          padding-right: ${maxPadding}px;
        }
    `;
