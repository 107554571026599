import { set, last } from 'lodash/fp';

export const mapToObject = arr =>
  arr.reduce((memo, item) => {
    // ignore products that are missing an ID
    if (!item.id) {
      return memo;
    }
    const productId = last(item.id.split('/'));
    return set(productId, item, memo);
  }, {});
