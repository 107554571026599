import axios, { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

if (!process.env.NEXT_PUBLIC_API_BASE_URL) {
  throw new Error('NEXT_PUBLIC_API_BASE_URL is not defined');
}

export const axiosSingleton = axios.create({
  timeout: 60000,
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

if (typeof window === 'undefined') {
  axiosSingleton.defaults.headers.common['Accept-Encoding'] = 'gzip, deflate';
}

// use for testing purposes (to log request)
/*
axiosSingleton.interceptors.request.use(request => {
  console.log('Starting Request', request);
  return request;
});
*/

export const fetchSearchResult = (params) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/search`, { params: { ...params } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchBootstrap = (params) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/bootstrap/init`, { params: { ...params } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchMessages = (params) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/bootstrap/messages`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchMenu = (params) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/bootstrap/menu`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchMarkets = (params) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/bootstrap/markets`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};
