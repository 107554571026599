/* eslint-disable no-underscore-dangle */
// Suspending the checkout
export const klarnaSuspend = () => {
  if (window._klarnaCheckout) {
    window._klarnaCheckout(api => {
      api.suspend();
    });
  }
};

// Resuming the checkout
export const klarnaResume = () => {
  if (window._klarnaCheckout) {
    window._klarnaCheckout(api => {
      api.resume();
    });
  }
};
