import { all, put, takeEvery, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { fetchMarkets } from '../houdini';

import { GET_MARKETS_REQUEST } from '../../store/actions/actionTypes';
import { marketsResponse } from '../../store/actions/actions';

es6promise.polyfill();

function* handleMarketsRequested(action) {
  try {
    const payload = yield call(fetchMarkets, action.payload);

    yield put(marketsResponse({ payload }));
  } catch (error) {
    yield put(marketsResponse({ error }));
  }
}

export const marketsSaga = function* marketsSaga() {
  yield all([takeEvery(GET_MARKETS_REQUEST, handleMarketsRequested)]);
};
