import { combineReducers } from 'redux';

import { products } from './products';
import { cart } from './cart';
import { viewState } from './viewState';
import { editorial } from './editorial';
import { bootstrap } from './bootstrap';
import { search } from './search';
import { productFilter } from './productFilter';
import { menu } from './menu';
import { gifts } from './gifts';
import { giftCards } from './giftCards';
import { markets } from './markets';
import { myPages } from './myPages';

const rootReducer = combineReducers({
  viewState,
  products,
  cart,
  editorial,
  bootstrap,
  search,
  productFilter,
  menu,
  gifts,
  giftCards,
  markets,
  myPages
});

export default rootReducer;
