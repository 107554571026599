// BOOTSTRAP
export const GET_BOOTSTRAP_REQUEST = 'GET_BOOTSTRAP_REQUEST';
export const GET_BOOTSTRAP_RESPONSE = 'GET_BOOTSTRAP_RESPONSE';

// CART
export const GET_CART_REQUEST = 'GET_CART_REQUEST';
export const GET_CART_RESPONSE = 'GET_CART_RESPONSE';
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_RESPONSE = 'ADD_TO_CART_RESPONSE';
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR';
export const SUBTRACT_FROM_CART_REQUEST = 'SUBTRACT_FROM_CART_REQUEST';
export const SUBTRACT_FROM_CART_RESPONSE = 'SUBTRACT_FROM_CART_RESPONSE';
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_RESPONSE = 'REMOVE_FROM_CART_RESPONSE';
export const CLEAR_CART_REQUEST = 'CLEAR_CART_REQUEST';
export const CLEAR_CART_RESPONSE = 'CLEAR_CART_RESPONSE';

// EDITORIAL
export const GET_EDITORIAL_REQUEST = 'GET_EDITORIAL_REQUEST';
export const GET_EDITORIAL_RESPONSE = 'GET_EDITORIAL_RESPONSE';
export const GET_EDITORIAL_STORES_REQUEST = 'GET_EDITORIAL_STORES_REQUEST';
export const GET_EDITORIAL_STORES_RESPONSE = 'GET_EDITORIAL_STORES_RESPONSE';

// PRODUCTS
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_RESPONSE = 'GET_PRODUCT_RESPONSE';
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_RESPONSE = 'GET_PRODUCTS_RESPONSE';
export const GET_PRODUCTS_FROM_CATEGORY_REQUEST = 'GET_PRODUCTS_FROM_CATEGORY_REQUEST';
export const GET_PRODUCTS_FROM_CATEGORY_RESPONSE = 'GET_PRODUCTS_FROM_CATEGORY_RESPONSE';
export const GET_PRODUCTS_FROM_LIST_REQUEST = 'GET_PRODUCTS_FROM_LIST_REQUEST';
export const GET_PRODUCTS_FROM_LIST_RESPONSE = 'GET_PRODUCTS_FROM_LIST_RESPONSE';
export const GET_PRODUCT_FILTER_REQUEST = 'GET_PRODUCT_FILTER_REQUEST';
export const GET_PRODUCT_FILTER_RESPONSE = 'GET_PRODUCT_FILTER_RESPONSE';

// VIEWSTATE
export const SET_VIEWSTATE = 'SET_VIEWSTATE';
export const REMOVE_VIEWSTATE = 'REMOVE_VIEWSTATE';

// SEARCH
export const GET_SEARCH_REQUEST = 'GET_SEARCH_REQUEST';
export const GET_SEARCH_RESPONSE = 'GET_SEARCH_RESPONSE';

// MENU
export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_RESPONSE = 'GET_MENU_RESPONSE';

// MARKETS
export const GET_MARKETS_REQUEST = 'GET_MARKETS_REQUEST';
export const GET_MARKETS_RESPONSE = 'GET_MARKETS_RESPONSE';

// GIFTS
export const GET_GIFTS_REQUEST = 'GET_GIFTS_REQUEST';
export const GET_GIFTS_RESPONSE = 'GET_GIFTS_RESPONSE';

// GIFT-CARDS
export const CHECK_GIFTCARD_REQUEST = 'CHECK_GIFTCARD_REQUEST';
export const CHECK_GIFTCARD_RESPONSE = 'CHECK_GIFTCARD_RESPONSE';
export const ADD_GIFTCARD_TO_CART_REQUEST = 'ADD_GIFTCARD_TO_CART_REQUEST';
export const ADD_GIFTCARD_TO_CART_RESPONSE = 'ADD_GIFTCARD_TO_CART_RESPONSE';
export const APPLY_GIFTCARD_REQUEST = 'APPLY_GIFTCARD_REQUEST';
export const APPLY_GIFTCARD_RESPONSE = 'APPLY_GIFTCARD_RESPONSE';
export const REMOVE_GIFTCARD_REQUEST = 'REMOVE_GIFTCARD_REQUEST';
export const REMOVE_GIFTCARD_RESPONSE = 'REMOVE_GIFTCARD_RESPONSE';
export const FETCH_GIFTCARD_SETTINGS_REQUEST = 'FETCH_GIFTCARD_SETTINGS_REQUEST';
export const FETCH_GIFTCARD_SETTINGS_RESPONSE = 'FETCH_GIFTCARD_SETTINGS_RESPONSE';

// MY-PAGES
export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_RESPONSE = 'GET_LOGIN_RESPONSE';
