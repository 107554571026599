import { all, put, takeEvery, race, call } from 'redux-saga/effects';
import { fetchProductFilter } from '../products';

import { GET_PRODUCT_FILTER_REQUEST } from '../../store/actions/actionTypes';
import { productFilterResponse } from '../../store/actions/actions';

function* handleProductFilterRequested(action) {
  const { meta } = action;
  try {
    const { payload } = yield race({
      payload: call(fetchProductFilter, meta)
    });
    yield put(productFilterResponse({ payload, meta }));
  } catch (error) {
    yield put(productFilterResponse({ error, meta }));
  }
}

export const productFilterSaga = function* productSaga() {
  yield all([takeEvery(GET_PRODUCT_FILTER_REQUEST, handleProductFilterRequested)]);
};
