import { set, isEqual, map, get, merge, compose, filter } from 'lodash/fp';
import { combineReducers } from 'redux';
import { GET_SEARCH_REQUEST, GET_SEARCH_RESPONSE } from '../actions/actionTypes';

const mapObjectsToIds = payload =>
  compose(
    map(item => ({
      relevance: get('relevance', item),
      id: get(['hit', 'id'], item)
    })),
    filter(item => get(['hit', 'id'], item))
  )(get('products', payload));

function data(state = {}, { type, payload, error, meta }) {
  switch (type) {
    case GET_SEARCH_RESPONSE: {
      const transformedPayload = set('products', mapObjectsToIds(payload), payload);
      const next = set(get('q', meta), transformedPayload, state);
      return error || isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

function request(state = {}, { type, meta, error }) {
  switch (type) {
    case GET_SEARCH_REQUEST: {
      const next = set(get('q', meta), { status: 'LOADING' }, {});
      return merge(state, next);
    }

    case GET_SEARCH_RESPONSE: {
      const requestData = {
        status: error ? 'FAILURE' : 'SUCCESS',
        statusCode: error ? get(['response', 'status'], error) : 200
      };

      const next = set(get('q', meta), requestData, state);
      return isEqual(next, state) ? state : next;
    }

    default:
      return state;
  }
}

export const search = combineReducers({ data, request });
