import { all, put, takeLatest, call } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { fetchCart, addToCart, subtractFromCart, removeFromCart, clearCart } from '../cart';
import { addToCartEvent, removeFromCartEvent } from '../../helpers/gtm';
import {
  GET_CART_REQUEST,
  SUBTRACT_FROM_CART_REQUEST,
  REMOVE_FROM_CART_REQUEST,
  CLEAR_CART_REQUEST,
  ADD_TO_CART_REQUEST
} from '../../store/actions/actionTypes';
import {
  cartResponse,
  addToCartResponse,
  subtractFromCartResponse,
  removeFromCartResponse,
  clearCartResponse,
  addToCartError,
  productRequest
} from '../../store/actions/actions';

es6promise.polyfill();

function* handleGetCartRequest(action) {
  const { meta } = action;

  try {
    const payload = yield call(fetchCart, meta);
    yield put(cartResponse({ payload }));
  } catch (error) {
    yield put(cartResponse({ error }));
  }
}

function* handleAddToCartRequest(action) {
  const { meta } = action;
  const { productId, market, updatePaymentProvider, isAdyen } = meta;
  try {
    const response = yield call(addToCart, meta?.id, { market, updatePaymentProvider }, isAdyen);

    // fire GTM-event
    addToCartEvent({ id: meta?.id, cart: response }, meta?.currencyCode);

    yield put(addToCartResponse({ payload: response }));
  } catch (error) {
    if (error?.response?.status === 400) {
      if (productId) {
        yield put(productRequest({ id: productId, market }));
      }

      yield put(
        addToCartError({
          payload: meta?.id,
          timestamp: Date.now(),
          message: error?.response?.data?.errorMessage
        })
      );
    } else {
      yield put(addToCartResponse({ error }));
    }
  }
}

function* handleSubtractFromCartRequest(action) {
  const { meta } = action;
  const { market, updatePaymentProvider, isAdyen } = meta;

  try {
    const payload = yield call(
      subtractFromCart,
      meta?.orderRow?.orderRowId,
      { market, updatePaymentProvider },
      isAdyen
    );

    // fire GTM-event
    removeFromCartEvent({ orderRow: meta?.orderRow }, meta?.currencyCode);

    yield put(subtractFromCartResponse({ payload }));
  } catch (error) {
    yield put(subtractFromCartResponse({ error }));
  }
}

function* handleRemoveFromCartRequest(action) {
  const { meta } = action;
  const { market, updatePaymentProvider, isAdyen } = meta;

  try {
    const payload = yield call(removeFromCart, meta?.orderRow?.orderRowId, { market, updatePaymentProvider }, isAdyen);

    // fire GTM-event
    removeFromCartEvent(
      {
        orderRow: meta?.orderRow,
        quantity: meta?.orderRow?.quantity
      },
      meta?.currencyCode
    );

    yield put(removeFromCartResponse({ payload }));
  } catch (error) {
    yield put(removeFromCartResponse({ error }));
  }
}

function* handleCleanCartRequest(action) {
  try {
    const payload = yield call(clearCart, action.payload);
    yield put(clearCartResponse({ payload }));
  } catch (error) {
    yield put(clearCartResponse({ error }));
  }
}

export const cartSaga = function* cartSaga() {
  yield all([
    yield takeLatest(GET_CART_REQUEST, handleGetCartRequest),
    yield takeLatest(ADD_TO_CART_REQUEST, handleAddToCartRequest),
    yield takeLatest(SUBTRACT_FROM_CART_REQUEST, handleSubtractFromCartRequest),
    yield takeLatest(REMOVE_FROM_CART_REQUEST, handleRemoveFromCartRequest),
    yield takeLatest(CLEAR_CART_REQUEST, handleCleanCartRequest)
  ]);
};
