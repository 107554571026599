import { css } from 'styled-components';

export const fonts = {
  NeoSansLight: css`
    font-family: neo-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
  `,
  NeoSansRegular: css`
    font-family: neo-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
  `,
  NeoSansMedium: css`
    font-family: neo-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-style: normal;
  `,
  GoudyOldStyleRegular: css`
    font-family: goudy-old-style, Arial Narrow, serif;
    font-weight: 400;
    font-style: normal;
  `,
  GoudyOldStyleItalic: css`
    font-family: goudy-old-style, Arial Narrow, serif;
    font-weight: 400;
    font-style: italic;
  `,
};
