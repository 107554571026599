import { combineReducers } from 'redux';
import { get } from 'lodash/fp';
import {
  GET_CART_REQUEST,
  GET_CART_RESPONSE,
  ADD_TO_CART_RESPONSE,
  ADD_TO_CART_ERROR,
  SUBTRACT_FROM_CART_RESPONSE,
  REMOVE_FROM_CART_RESPONSE,
  CLEAR_CART_RESPONSE,
  ADD_TO_CART_REQUEST,
  REMOVE_FROM_CART_REQUEST,
  CLEAR_CART_REQUEST,
  SUBTRACT_FROM_CART_REQUEST
} from '../actions/actionTypes';
import { SUCCESS, LOADING, FAILURE, ERROR_ADD_TO_CART } from '../../constants/requestStatuses';

function data(state = {}, { type, payload, error }) {
  switch (type) {
    case GET_CART_RESPONSE: {
      return error || payload;
    }

    case ADD_TO_CART_RESPONSE: {
      if (error) {
        return error?.response?.status === 400 ? state : error;
      }
      return payload;
    }

    case SUBTRACT_FROM_CART_RESPONSE: {
      return error || payload;
    }

    case REMOVE_FROM_CART_RESPONSE: {
      return error || payload;
    }

    case CLEAR_CART_RESPONSE: {
      return error || payload;
    }

    default:
      return state;
  }
}

const request = (state = {}, { type, error, payload, timestamp, message }) => {
  switch (type) {
    case ADD_TO_CART_ERROR:
      return { status: ERROR_ADD_TO_CART, productId: payload, timestamp, message };

    case GET_CART_REQUEST:
    case ADD_TO_CART_REQUEST:
    case SUBTRACT_FROM_CART_REQUEST:
    case REMOVE_FROM_CART_REQUEST:
    case CLEAR_CART_REQUEST:
      return { status: LOADING };

    case GET_CART_RESPONSE:
    case ADD_TO_CART_RESPONSE:
    case SUBTRACT_FROM_CART_RESPONSE:
    case REMOVE_FROM_CART_RESPONSE:
    case CLEAR_CART_RESPONSE:
      return error
        ? { status: FAILURE, statusCode: get('response.status', error) }
        : { status: SUCCESS, statusCode: 200 };

    default:
      return state;
  }
};
export const cart = combineReducers({ data, request });
