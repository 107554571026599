import { all, put, takeEvery, race, take, call } from 'redux-saga/effects';
import { getOr } from 'lodash/fp';
import { fetchEditorial, fetchEditorialStores } from '../editorial';

import { GET_EDITORIAL_REQUEST, GET_EDITORIAL_STORES_REQUEST } from '../../store/actions/actionTypes';
import { editorialResponse, editorialStoresResponse } from '../../store/actions/actions';

function* handleEditorialRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload, cancel } = yield race({
      payload: call(fetchEditorial, meta),
      cancel: take(GET_EDITORIAL_REQUEST)
    });

    if (cancel) {
      // yield put(editorialResponse({ status: 'CANCELED' }));
    }

    yield put(editorialResponse({ payload, meta }));
  } catch (error) {
    yield put(editorialResponse({ error, meta }));
  }
}

function* handleEditorialStoresRequested(action) {
  const meta = getOr({}, 'meta', action);

  try {
    const { payload, cancel } = yield race({
      payload: call(fetchEditorialStores, meta),
      cancel: take(GET_EDITORIAL_STORES_REQUEST)
    });

    if (cancel) {
      // yield put(editorialStoresResponse({ status: 'CANCELED' }));
    }

    yield put(editorialStoresResponse({ payload, meta }));
  } catch (error) {
    yield put(editorialStoresResponse({ error, meta }));
  }
}

export const editorialSaga = function* editorialSaga() {
  yield all([
    takeEvery(GET_EDITORIAL_REQUEST, handleEditorialRequested),
    takeEvery(GET_EDITORIAL_STORES_REQUEST, handleEditorialStoresRequested)
  ]);
};
