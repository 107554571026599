import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';
import { axiosSingleton } from './houdini';
import { resumeCheckout, suspendCheckout } from './cart';

export const checkGiftCard = (payload, config) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/giftcard/validate`, payload, {
      params: {
        market: config.market
      }
    })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const applyGiftCard = ({ code, isAdyen }, config) => {
  const source = CancelToken.source();
  suspendCheckout(true, isAdyen);
  const request = axiosSingleton
    .post(
      `/checkout/setGiftCard`,
      { code },
      {
        params: {
          market: config.market
        }
      }
    )
    .then(({ data }) => {
      resumeCheckout(true, isAdyen);
      return data;
    })
    .catch(error => {
      resumeCheckout(true, isAdyen);
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const removeGiftCard = ({ code, isAdyen }, config) => {
  const source = CancelToken.source();
  suspendCheckout(true, isAdyen);
  const request = axiosSingleton
    .post(
      `/checkout/removeGiftCard`,
      { code },
      {
        params: {
          market: config.market
        }
      }
    )
    .then(({ data }) => {
      resumeCheckout(true, isAdyen);
      return data;
    })
    .catch(error => {
      resumeCheckout(true, isAdyen);
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const addGiftCard = (payload, config) => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .post(`/cart/addGiftCard`, payload, {
      params: {
        market: config.market
      }
    })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchGiftCardSettings = params => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/giftcard/settings`, {
      params: {
        market: params.market
      }
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};
