import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';
import { axiosSingleton } from './houdini';

export const fetchEditorial = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/editorial/pages`, { params: payload })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const fetchEditorialStores = payload => {
  const source = CancelToken.source();
  const request = axiosSingleton
    .get(`/editorial/stores`, { params: payload })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
  request[CANCEL] = () => source.cancel();
  return request;
};
