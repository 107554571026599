import { NEXT_PUBLIC_SITE_BASE_URL } from '../constants/site';

const sendVoyadoEvent = ({ type, data }) => {
  if (typeof window !== 'undefined' && typeof va !== 'undefined') {
    va(type, data); // eslint-disable-line no-undef
  }
};

export const voyadoCartTracking = (cart, market) => {
  if (!cart?.cartId) {
    return false;
  }
  if (cart?.orderRows?.length === 0) {
    sendVoyadoEvent({
      type: 'emptyCart',
      data: {
        cartRef: cart?.cartId,
      },
    });
    return false;
  }
  sendVoyadoEvent({
    type: 'cart',
    data: {
      cartRef: cart?.cartId,
      cartUrl:
        cart?.cartId &&
        `${NEXT_PUBLIC_SITE_BASE_URL}${market ? `/${market}` : ''}?cartId=${
          cart?.cartId
        }&modal=cart`,
      locale:
        market &&
        `${market?.split('-')[0]}-${market?.split('-')[1]?.toUpperCase()}`,
      items: cart?.orderRows?.map((orderRow) => ({
        itemId: orderRow?.product?.size?.articleId,
        quantity: orderRow?.quantity,
      })),
    },
  });
  return false;
};

export const voyadoContactId = (contactId) => {
  if (!contactId) {
    return false;
  }
  sendVoyadoEvent({
    type: 'setContactId',
    data: contactId,
  });
  return false;
};
