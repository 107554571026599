import {
  SET_VIEWSTATE,
  REMOVE_VIEWSTATE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_RESPONSE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_RESPONSE,
  GET_PRODUCTS_FROM_CATEGORY_REQUEST,
  GET_PRODUCTS_FROM_CATEGORY_RESPONSE,
  GET_PRODUCTS_FROM_LIST_REQUEST,
  GET_PRODUCTS_FROM_LIST_RESPONSE,
  GET_CART_REQUEST,
  GET_CART_RESPONSE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_RESPONSE,
  ADD_TO_CART_ERROR,
  SUBTRACT_FROM_CART_REQUEST,
  SUBTRACT_FROM_CART_RESPONSE,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_RESPONSE,
  CLEAR_CART_REQUEST,
  CLEAR_CART_RESPONSE,
  GET_EDITORIAL_REQUEST,
  GET_EDITORIAL_RESPONSE,
  GET_EDITORIAL_STORES_REQUEST,
  GET_EDITORIAL_STORES_RESPONSE,
  GET_BOOTSTRAP_REQUEST,
  GET_BOOTSTRAP_RESPONSE,
  GET_SEARCH_REQUEST,
  GET_SEARCH_RESPONSE,
  GET_PRODUCT_FILTER_REQUEST,
  GET_PRODUCT_FILTER_RESPONSE,
  GET_MENU_REQUEST,
  GET_MENU_RESPONSE,
  GET_GIFTS_RESPONSE,
  GET_GIFTS_REQUEST,
  CHECK_GIFTCARD_RESPONSE,
  CHECK_GIFTCARD_REQUEST,
  ADD_GIFTCARD_TO_CART_REQUEST,
  ADD_GIFTCARD_TO_CART_RESPONSE,
  APPLY_GIFTCARD_REQUEST,
  APPLY_GIFTCARD_RESPONSE,
  REMOVE_GIFTCARD_REQUEST,
  REMOVE_GIFTCARD_RESPONSE,
  FETCH_GIFTCARD_SETTINGS_REQUEST,
  FETCH_GIFTCARD_SETTINGS_RESPONSE,
  GET_MARKETS_REQUEST,
  GET_MARKETS_RESPONSE,
  GET_LOGIN_REQUEST,
  GET_LOGIN_RESPONSE
} from './actionTypes';

export const setViewstate = (id, value) => ({
  type: SET_VIEWSTATE,
  payload: {
    id,
    value
  }
});

export const removeViewstate = (id, value) => ({
  type: REMOVE_VIEWSTATE,
  payload: {
    id,
    value
  }
});

// PRODUCT
export const productRequest = id => ({ type: GET_PRODUCT_REQUEST, meta: id });
export const productResponse = response => ({ type: GET_PRODUCT_RESPONSE, ...response });

// PRODUCTS
export const productsRequest = payload => ({ type: GET_PRODUCTS_REQUEST, meta: payload });
export const productsResponse = response => ({ type: GET_PRODUCTS_RESPONSE, ...response });
export const productsFromCategoryRequest = payload => ({ type: GET_PRODUCTS_FROM_CATEGORY_REQUEST, meta: payload });
export const productsFromCategoryResponse = response => ({ type: GET_PRODUCTS_FROM_CATEGORY_RESPONSE, ...response });
export const productsFromListRequest = payload => ({ type: GET_PRODUCTS_FROM_LIST_REQUEST, meta: payload });
export const productsFromListResponse = response => ({ type: GET_PRODUCTS_FROM_LIST_RESPONSE, ...response });
export const productFilterRequest = payload => ({ type: GET_PRODUCT_FILTER_REQUEST, meta: payload });
export const productFilterResponse = response => ({ type: GET_PRODUCT_FILTER_RESPONSE, ...response });

// EDITORIAL
export const editorialRequest = id => ({ type: GET_EDITORIAL_REQUEST, meta: id });
export const editorialResponse = response => ({ type: GET_EDITORIAL_RESPONSE, ...response });

// SEARCH
export const searchRequest = meta => ({ type: GET_SEARCH_REQUEST, meta });
export const searchResponse = response => ({ type: GET_SEARCH_RESPONSE, ...response });

// EDITORIAL - STORES
export const editorialStoresRequest = id => ({ type: GET_EDITORIAL_STORES_REQUEST, meta: id });
export const editorialStoresResponse = response => ({ type: GET_EDITORIAL_STORES_RESPONSE, ...response });

// CART
export const cartRequest = payload => ({
  type: GET_CART_REQUEST,
  meta: payload
});

export const cartResponse = response => ({
  type: GET_CART_RESPONSE,
  ...response
});

export const addToCartRequest = payload => ({
  type: ADD_TO_CART_REQUEST,
  meta: payload
});

export const addToCartResponse = response => ({
  type: ADD_TO_CART_RESPONSE,
  ...response
});

export const addToCartError = response => ({
  type: ADD_TO_CART_ERROR,
  ...response
});

export const subtractFromCartRequest = payload => ({
  type: SUBTRACT_FROM_CART_REQUEST,
  meta: payload
});

export const subtractFromCartResponse = response => ({
  type: SUBTRACT_FROM_CART_RESPONSE,
  ...response
});

export const removeFromCartRequest = payload => ({
  type: REMOVE_FROM_CART_REQUEST,
  meta: payload
});

export const removeFromCartResponse = response => ({
  type: REMOVE_FROM_CART_RESPONSE,
  ...response
});

// NOT IN USE
export const clearCartRequest = payload => ({
  type: CLEAR_CART_REQUEST,
  payload
});

// NOT IN USE
export const clearCartResponse = response => ({
  type: CLEAR_CART_RESPONSE,
  ...response
});

// GIFTS
export const giftsRequest = payload => ({
  type: GET_GIFTS_REQUEST,
  payload
});

export const giftsResponse = response => ({
  type: GET_GIFTS_RESPONSE,
  ...response
});

// GIFTCARDS
export const checkGiftCardRequest = payload => ({
  type: CHECK_GIFTCARD_REQUEST,
  payload
});

export const checkGiftCardResponse = response => ({
  type: CHECK_GIFTCARD_RESPONSE,
  ...response
});

export const addGiftCardRequest = payload => ({
  type: ADD_GIFTCARD_TO_CART_REQUEST,
  payload
});

export const addGiftCardResponse = response => ({
  type: ADD_GIFTCARD_TO_CART_RESPONSE,
  ...response
});

export const applyGiftCardRequest = payload => ({
  type: APPLY_GIFTCARD_REQUEST,
  payload
});

export const applyGiftCardResponse = response => ({
  type: APPLY_GIFTCARD_RESPONSE,
  ...response
});

export const removeGiftCardRequest = payload => ({
  type: REMOVE_GIFTCARD_REQUEST,
  payload
});

export const removeGiftCardResponse = response => ({
  type: REMOVE_GIFTCARD_RESPONSE,
  ...response
});

export const fetchGiftCardSettingsRequest = payload => ({
  type: FETCH_GIFTCARD_SETTINGS_REQUEST,
  payload
});

export const fetchGiftCardSettingsResponse = response => ({
  type: FETCH_GIFTCARD_SETTINGS_RESPONSE,
  ...response
});

// BOOTSTRAP
export const bootstrapRequest = payload => ({
  type: GET_BOOTSTRAP_REQUEST,
  payload
});

export const bootstrapResponse = response => ({
  type: GET_BOOTSTRAP_RESPONSE,
  ...response
});

export const menuRequest = payload => ({ type: GET_MENU_REQUEST, payload });
export const menuResponse = response => ({ type: GET_MENU_RESPONSE, ...response });

// MARKETS
export const marketsRequest = payload => ({ type: GET_MARKETS_REQUEST, payload });
export const marketsResponse = response => ({ type: GET_MARKETS_RESPONSE, ...response });

export const loginRequest = payload => ({ type: GET_LOGIN_REQUEST, meta: payload });
export const loginResponse = response => ({ type: GET_LOGIN_RESPONSE, ...response });
