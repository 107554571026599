export const ingridSuspend = () => {
  if (window._sw) {
    window._sw(api => api.suspend());
  }
};

export const ingridResume = () => {
  if (window._sw) {
    window._sw(api => api.resume());
  }
};
